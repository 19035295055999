import React, { useEffect, useState } from "react";
import { Layout, Menu, message } from "antd";
import cookies from "js-cookie";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { Box, IconButton, Typography, useTheme, Collapse } from "@mui/material";
import { tokens } from "../../theme";
import dashboard_pressed from "../../assets/img/Dahsboard_pressed.png";
import dashboard_ico from "../../assets/img/Dashboard.png";
import loan_ico from "../../assets/img/LoanLogo.png";
import loan_pressed from "../../assets/img/LoanLogo_pressed.png";
import agreements_ico from "../../assets/img/Agreementlogo.png";
import agreements_pressed from "../../assets/img/Agreementlogo_pressed.png";
import subscription_ico from "../../assets/img/Subscription.png";
import subscription_pressed from "../../assets/img/SubscriptionLogo_pressed.png";
import profile_ico from "../../assets/img/Profile.png";
import profile_pressed from "../../assets/img/Profile_selected.png";
import logout_ico from "../../assets/img/Logout.png";
import LockIcon from "@mui/icons-material/Lock";
import SidebarMobile from "./SidebarMobile";
import Hamburger from "hamburger-react";
import { fallDown as BurgerMenu } from "react-burger-menu";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Cookies from "js-cookie";
import Tour from "reactour";
import TourIcon from "@mui/icons-material/Tour";
import ReviewsIcon from "@mui/icons-material/Reviews";
import { Modal } from "antd";
import {
  UserOutlined,
  UploadOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import ForumIcon from "@mui/icons-material/Forum";
import newLogo from "../../pages/smart-lend-frontend/src/img/sl_long_new_logo_wo_beta.png";
import GroupsIcon from "@mui/icons-material/Groups";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";

const { Sider } = Layout;
const { SubMenu } = Menu;

const Item3 = ({
  title,
  title2,
  to,
  icon,
  selected,
  setSelected,
  subMenu,
  icon2,
  onClick, // New prop for onClick function
  disabled, // New prop for disabling button
}) => {
  const match = useRouteMatch(to);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [hovered, setHovered] = useState(false);

  return (
    <Menu.Item
      key={title}
      icon={hovered ? icon : icon2}
      style={{
        marginLeft: "15px",
        marginBottom: "20px",
        paddingLeft: "20px",
        background: hovered ? "#0E5AA5" : "#FFFFFF",
        opacity: 1,
        width: "80%",
        color: hovered ? "#FFFFFF" : "#0E5AA5",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
      }}
      onMouseEnter={() => setHovered(true)} // set the state hovered to true  when mouse enter
      onMouseLeave={() => setHovered(false)} // set the state hovered to false  when mouse leave
      onClick={(event) => onClick(event)} // Attaching onClick here
      disabled={disabled} // Attaching disabled here
    >
      <div>
        <Typography
          style={{
            color: hovered ? "#FFFFFF" : "rgba(0,0,0,0.6)",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            textAlign: "Left",
          }}
        >
          {title}
        </Typography>

        <Typography
          style={{
            color: match ? "#0E5AA5" : "rgba(0,0,0,0.6)",
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: "13px",
            textAlign: "Left",
            paddingLeft: "20px",
          }}
        >
          {title2}
        </Typography>
      </div>
      <Link to={to} />
    </Menu.Item>
  );
};

const Sidebar = (to) => {
  const urlPram = useHistory();

  const [anyUnread, setAnyUnread] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [verifiedStatus, setVerifiedStatus] = useState(
    parseFloat(Cookies.get("verificationStatus")) || 0
  );
  const [loanExist, setLoanExist] = useState(
    parseFloat(Cookies.get("loanExist") || 0)
  );
  const [AccountManagerExist, setAccountManagerExist] = useState(
    parseFloat(Cookies.get("accountmanagerexist") || 0)
  );

  const fetchLoanCount = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/checkLoanRequestCount",
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      } else {
        const data = await response.json();
        if (data.loanRequestCount > 0) {
          setLoanExist(true);
          Cookies.set("loanExist", 1);
        } else {
          setLoanExist(false);
          Cookies.set("loanExist", 0);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [accountManagerDetails, setAccountManagerDetails] = useState(null);
  const [accountmanagerModalIsOpen, setAccountmanagerModalIsOpen] =
    useState(false);

  const getAccountManagerDetails = async () => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/getAccountManagerDetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        if (json.accountManager !== null) {
          setAccountManagerExist(true);
          Cookies.set("accountmanagerexist", 1);
          setAccountManagerDetails(JSON.parse(json.accountManager));
        } else {
          setAccountManagerExist(false);
          Cookies.set("accountmanagerexist", 0);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getAccountManagerDetails();
  }, []);

  useEffect(() => {
    fetchLoanCount();
    if (Cookies.get("signupthroughcbs")) {
      history.push("/b-cbs-campaign");
    }
  }, []);

  const getUserVerificationFiles = async () => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/getUserVerificationFiles`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (response.ok) {
        const json = await response.json();
        if (json.verificationStatus === 0 || json.verificationStatus === 1) {
          setVerifiedStatus(json.verificationStatus);
          Cookies.set("verificationStatus", json.verificationStatus);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log(Cookies.get("verificationStatus"));
    getUserVerificationFiles();
  }, []);

  useEffect(() => {
    if (!Cookies.get("tourDone")) {
      setIsTourOpen(true);
      Cookies.set("tourDone", true);
    }
  }, []);

  const tourSteps = [
    {
      selector: ".step1",
      content:
        "Welcome to SmartLend! Ready to start your business loan application?",
    },
    {
      selector: ".step2",
      content:
        "Scan your Corppass to quickly fill out your application details",
    },
    {
      selector: ".step3",
      content: "Apply for your loan here and get access to multiple lenders.",
    },
    {
      selector: ".step4",
      content:
        "Compare and choose the best loan offer that suits your business needs.",
    },
    {
      selector: ".step5",
      content:
        "Complete your loan agreement to access funds for your working capital needs.",
    },
    {
      selector: ".step6",
      content: "Check your on-going loan and terms. ",
    },
    {
      selector: ".step7",
      content: "And that's it! Ready to start your business loan application?",
    },
  ];

  const [feedbackModal, setFeedbackModal] = useState(false);
  const Item = ({
    className,
    title,
    title2,
    to,
    icon,
    selected,
    setSelected,
    subMenu,
    icon2,
    blinking,
  }) => {
    const match = useRouteMatch(to);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
      <div className={blinking ? "blink" : ""}>
        <Menu.Item
          className={className}
          // key={title}
          icon={match ? icon : icon2}
          style={{
            paddingLeft: match ? "24px" : "30px",
            borderLeft: match ? "6px solid #0E5AA5" : "none",
            marginLeft: "-0.03vw",
            borderRadius: "0px 8px 8px 0px",
            height: title ? "35px" : "30px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          onClick={() => {
            if (to !== "/b-coming-soon") {
              setSelected(title);
            }
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {title ? (
              <div
                style={{ display: "flex", alignItems: "flex-start", gap: 30 }}
              >
                <p
                  style={{
                    color: match
                      ? "#0E5AA5"
                      : to !== "/b-coming-soon"
                      ? blinking
                        ? "#1871c9"
                        : "rgba(0,0,0,0.6)"
                      : blinking
                      ? "#1871c9"
                      : "lightgrey",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 700,
                    fontSize: "13px",
                    textAlign: "Left",
                    margin: "0px",
                  }}
                >
                  {title}
                </p>
                {anyUnread && title === "Messages" ? (
                  <div
                    className="blink"
                    style={{
                      position: "absolute",
                      width: 7,
                      height: 7,
                      borderRadius: "50%",
                      background: "#0E5AA5",
                      bottom: 14,
                      right: 14,
                    }}
                  />
                ) : null}
              </div>
            ) : (
              <></>
            )}

            {title2 ? (
              <div
                style={{ display: "flex", alignItems: "flex-start", gap: 30 }}
              >
                <p
                  style={{
                    color: match
                      ? "#0E5AA5"
                      : to !== "/b-coming-soon"
                      ? "rgba(0,0,0,0.6)"
                      : "lightgrey",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 700,
                    fontSize: "12px",
                    textAlign: "Left",
                    paddingLeft: "35px",
                    margin: "0px",
                  }}
                >
                  {title2}
                </p>
                {anyUnread && title === "Messages" ? (
                  <div
                    className="blink"
                    style={{
                      position: "absolute",
                      width: 7,
                      height: 7,
                      borderRadius: "50%",
                      background: "#0E5AA5",
                      bottom: 14,
                      right: 14,
                    }}
                  />
                ) : null}
              </div>
            ) : (
              <></>
            )}
          </div>
          {to !== "/b-coming-soon" ? <Link to={to} /> : null}
        </Menu.Item>
      </div>
    );
  };
  const checkReadId = (data) => {
    let anyUnread = false; // Variable to track if any message is unread

    for (const data1 of data) {
      const { read_id1, read_id2, user_id1, user_id2, receiver } = data1;

      if (user_id1 === receiver) {
        if (read_id1) {
          anyUnread = true;
        }
      } else {
        if (read_id2) {
          anyUnread = true;
        }
      }
    }

    // Set anyUnread based on the condition
    setAnyUnread(anyUnread);
  };

  const retrieveMessage = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/message/getMessage",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const json = await response.json();
      if (response.status === 200) {
        const data = [...json.data];
        console.log("updated new data");
        checkReadId(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      retrieveMessage();
    }, 6000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    retrieveMessage();
  }, []);
  useEffect(() => {
    // Function to get the value of a URL parameter by name
    const getUrlParameter = (name) => {
      name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
      const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      const results = regex.exec(window.location.search);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    };

    // Get the token parameter from the URL
    const tokenParam = getUrlParameter("token");

    // Check if a token is present in the URL
    if (tokenParam) {
      // Store the token in cookies
      Cookies.set("token", tokenParam, { expires: 7 }); // Set an expiration if needed

      // Remove the token parameter from the URL
      const urlWithoutToken =
        window.location.pathname +
        window.location.search
          .replace(/([&?])token=[^&]+(&|$)/, "$1")
          .replace(/(&|\?)$/, "");

      // Replace the current URL without adding a new entry to the browser urlPram
      urlPram.replace(urlWithoutToken);
    }
  }, [urlPram]);
  const match = useRouteMatch(to);
  const [isLoading, setIsLoading] = useState(false);

  const handleLogout = async () => {
    setIsLoading(true);
    cookies.remove("token");
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/user/logout",
        {
          method: "POST",
          redirect: "manual", // don't follow the redirect
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );
      console.log("response status value", response.status);
      if (response.status === 200) {
        // Clear user session and navigate to login page
        window.location.href = "/auth/signin";
      } else if (response.status === 0) {
        window.location.href = "/auth/signin";
      }
      // if (!response.ok) {
      //   throw new Error("Logout failed");
      // }
    } catch (error) {
      console.error(error);
      // Display error message to user
      window.location.href = "/auth/signin";
    }
    setIsLoading(false);
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const toggleCollapsed = () => {
    setIsCollapsed(isCollapsed);
  };

  const [screenWidth, setScreenWidth] = useState("1920");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const history = useHistory();

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [feedbackForm, setFeedbackForm] = useState({
    category: "",
    image: "",
    message: "",
    status_1: "",
    status_2: "",
    status_3: "",
  });

  function handleToggleMenu(event) {
    setMenuIsOpen(!menuIsOpen);
  }
  const handleFileChange = async (file) => {
    const formData = new FormData();
    console.log(file);
    formData.append("file", file);
    try {
      const response1 = await fetch(
        "https://www.smart-lend.com/api/file/uploadUniversalFile",
        {
          method: "POST",
          body: formData,
        }
      );
      if (response1.status === 200) {
        const data = await response1.json();
        const imageUrl = data.url;
        console.log({ ...feedbackForm, image: imageUrl });
        setFeedbackForm({ ...feedbackForm, image: imageUrl });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateFeedback = async () => {
    try {
      let dataTosend = {
        submission_data: JSON.stringify({
          category: feedbackForm.category,
          image: feedbackForm.image,
          message: feedbackForm.message,
        }),
        status_1: "created",
        status_2: "",
        status_3: "",
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/user/feedback/createFeedback",
        {
          method: "POST",
          body: JSON.stringify(dataTosend),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        console.log(json);
        setFeedbackModal(false);
        message.success("Your feedback has been submitted!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {screenWidth > 1200 ? (
        <>
          <Modal
            style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            open={accountmanagerModalIsOpen}
            onCancel={() => {
              setAccountmanagerModalIsOpen(false);
            }}
            footer={null}
          >
            {accountManagerDetails ? (
              <div
                style={{
                  color: "rgba(0,0,0,0.4)",
                  fontSize: "10px",
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "500",
                }}
              >
                <div
                  style={{
                    color: "#142C44CC",
                    fontSize: "18px",
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "800",
                  }}
                >
                  Account Manager details
                </div>
                <div style={{ display: "flex", gap: "30px" }}>
                  <div>
                    {accountManagerDetails?.profile ? (
                      <img
                        style={{
                          height: "90px",
                          width: "90px",
                          borderRadius: "50%",
                          overflow: "hidden",
                          objectFit: "cover",
                          marginTop: "15px",
                          boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                        }}
                        src={accountManagerDetails.profile}
                      />
                    ) : null}
                  </div>
                  <div>
                    <div
                      style={{
                        marginTop: "15px",
                      }}
                    >
                      Name
                    </div>
                    <div
                      style={{
                        color: "#142C44CC",
                        fontSize: "11px",
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "600",
                      }}
                    >
                      {accountManagerDetails.name || "Not provided"}
                    </div>
                    <div style={{ marginTop: "7px" }}>Phone number</div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                      className="mui_button"
                      onClick={() => {
                        if (accountManagerDetails.ph) {
                          const cleanPhone = accountManagerDetails.ph
                            .replace(/\D/g, "") // Remove all non-digit characters
                            .replace(/^0+/, ""); // Optionally remove leading zeros (depends on your use case)

                          window.location.href = `https://wa.me/${cleanPhone}`;
                        }
                      }}
                    >
                      {accountManagerDetails.ph ? (
                        <WhatsAppIcon
                          style={{ color: "#142C44CC", fontSize: "24px" }}
                        />
                      ) : null}
                      <div
                        style={{
                          color: "#142C44CC",
                          fontSize: "11px",
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: "600",
                        }}
                      >
                        {accountManagerDetails.ph || "Not provided"}
                      </div>
                    </div>
                    <div style={{ marginTop: "7px" }}>Email</div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                      className="mui_button"
                      onClick={() => {
                        if (accountManagerDetails.email) {
                          window.location.href = `mailto:${accountManagerDetails.email}`;
                        }
                      }}
                    >
                      {accountManagerDetails.email ? (
                        <EmailIcon
                          style={{ color: "#142C44CC", fontSize: "24px" }}
                        />
                      ) : null}
                      <div
                        style={{
                          color: "#142C44CC",
                          fontSize: "11px",
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: "600",
                        }}
                      >
                        {accountManagerDetails.email || "Not provided"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </Modal>
          <Modal
            width={screenWidth < 1200 ? "100%" : "50%"}
            style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            open={feedbackModal}
            onCancel={() => {
              setFeedbackModal(false);
            }}
            footer={null}
          >
            <div
              style={{
                color: "#142C44CC",
                fontWeight: 800,
                fontSize: "18px",
                fontFamily: "Manrope, sans-serif",
              }}
            >
              Please leave your feedback below as detailed as it can be.
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                console.log("submit");
                handleCreateFeedback();
              }}
            >
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "5px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Select a category for your feedback submission.
              </div>
              <select
                placeholder=""
                style={{
                  fontSize: "12px",
                  fontWeight: 400,
                  borderRadius: "8px",
                }}
                value={feedbackForm.category}
                onChange={(e) => {
                  const updatingFeedbackForm = { ...feedbackForm };
                  updatingFeedbackForm.category = e.target.value;
                  // console.log(updatingFeedbackForm);
                  setFeedbackForm(updatingFeedbackForm);
                }}
              >
                <option value={""}>Select a category</option>
                <option value={"Report a bug"}>Report a bug</option>
                <option value={"Request a feature"}>Request a feature</option>
                <option value={"Suggest an improvement"}>
                  Suggest an improvement
                </option>
                <option value={"Report a user"}>Report a user</option>
                <option value={"None of the above"}>None of the above</option>
              </select>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "5px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                If you have a screenshot of a problem, you may upload it here.
              </div>
              <input
                type="file"
                accept="image/*"
                style={{ fontSize: "12px", fontWeight: 400 }}
                onChange={(e) => handleFileChange(e.target.files[0])}
              />
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "5px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Provide a detailed description of the feedback.
              </div>
              <textarea
                value={feedbackForm.message}
                onChange={(e) => {
                  const updatingFeedbackForm = { ...feedbackForm };
                  updatingFeedbackForm.message = e.target.value;
                  // console.log(updatingFeedbackForm);
                  setFeedbackForm(updatingFeedbackForm);
                }}
                style={{
                  resize: "none",
                  width: "100%",
                  fontSize: "12px",
                  fontWeight: 400,
                  padding: "7px",
                  height: "12vh",
                  borderRadius: "8px",
                }}
              />
              <button
                type="submit"
                style={{
                  background: "rgb(17, 92, 166)",
                  color: "white",
                  padding: "7px 30px",
                  border: "none",
                  marginTop: "10px",
                  borderRadius: "8px",
                }}
              >
                Submit feedback
              </button>
            </form>
          </Modal>
          <Tour
            steps={tourSteps}
            style={{ color: "black" }}
            isOpen={isTourOpen}
            onRequestClose={() => setIsTourOpen(false)}
          />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            style={{
              height: "100vh",
              backgroundColor: "#F1F6F9",
            }}
          >
            {/* LOGO AND MENU ICON */}
            <Menu
              // theme="dark"
              mode="inline"
              defaultSelectedKeys={[selected]}
              selectedKeys={[selected]}
              style={{
                background: "#F1F6F9",
                width: "250px",
              }}
            >
              <Menu.Item style={{ marginTop: "30px", marginBottom: "10px" }}>
                {!isCollapsed && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    margin="34px 20px 20px 0px"
                  >
                    {/* <Typography
                      variant="h3"
                      color={colors.grey[930]}
                      opacity="1"
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "800",
                        fontSize: "25px",
                        textAlign: "center",
                      }}
                    >
                      SmartLend.
                    </Typography> */}

                    <img
                      src={newLogo}
                      style={{ width: "100%", objectFit: "contain" }}
                    />
                  </Box>
                )}
              </Menu.Item>
              <div
                style={{
                  marginLeft: "28px",
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Link
                  style={{
                    padding: "10px 20px",
                    color: "white",
                    background: verifiedStatus ? "rgb(14, 90, 165)" : "grey",
                    borderRadius: "6px",
                    marginTop: "5px",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  to={"/stg-b-profile"}
                  onClick={() => {
                    setSelected("Profile");
                  }}
                >
                  {verifiedStatus ? "Verified" : "Unverified"}
                </Link>
                <tooltip
                  style={{ height: "15px", width: "15px" }}
                  placement="topRight"
                  title="This represents the status in which your profile is verified by the platform, by verifying your profile in our platform, you build a better credibility in the eyes of the lenders."
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid grey",
                      borderRadius: "50%",
                      height: "15px",
                      width: "15px",
                    }}
                  >
                    <QuestionOutlined style={{ fontSize: "9px" }} />
                  </div>
                </tooltip>
              </div>
              {AccountManagerExist ? (
                <div
                  style={{
                    color: "rgba(0,0,0,0.4)",
                    marginTop: "5px",
                    marginLeft: "28px",
                    width: "calc(100% - 50px)",
                    display: "flex",
                  }}
                >
                  <div
                    className="mui_button"
                    onClick={() => {
                      setAccountmanagerModalIsOpen(true);
                    }}
                    style={{
                      padding: "5px 20px",
                      borderRadius: "6px",
                      color: "white",
                      background: "rgb(14, 90, 165)",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <GroupsIcon style={{ color: "white", fontSize: "20px" }} />
                    Account Manager
                  </div>
                </div>
              ) : null}
              <div style={{ paddingBottom: "32px" }}></div>
              <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
                <Item
                  title="Dashboard"
                  to="/b-dashboard"
                  icon={
                    <img
                      src={dashboard_pressed}
                      alt=""
                      width={12}
                      height={12}
                    />
                  }
                  icon2={
                    <img src={dashboard_ico} alt="" width={12} height={12} />
                  }
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Messages"
                  to="/b-messages"
                  icon={
                    <ForumIcon
                      style={{
                        fontSize: "18px",
                        marginLeft: "-3px",
                        color: "rgb(14, 90, 165)",
                      }}
                    />
                  }
                  icon2={
                    <ForumIcon
                      style={{
                        fontSize: "18px",
                        marginLeft: "-3px",
                        color: "rgba(0,0,0, 0.3)",
                      }}
                    />
                  }
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="Loan"
                  to="/b-apply-loan"
                  icon={
                    <img src={loan_pressed} alt="" width={12} height={12} />
                  }
                  icon2={<img src={loan_ico} alt="" width={12} height={12} />}
                  selected={selected}
                  setSelected={setSelected}
                  onClick={() => {
                    setSelected("Loan");
                  }}
                >
                  <Link
                    to="/loans"
                    style={{ color: "inherit", textDecoration: "none" }}
                  />
                </Item>

                <Item
                  className={"step3"}
                  title2="Apply Loan"
                  to="/b-apply-loan"
                  icon={" "}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  className={"step4"}
                  title2="Requested Loans"
                  to="/b-requested-loans"
                  icon={" "}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  className={"step5"}
                  title2="Accepted Loans"
                  to="/b-accepted-loans"
                  icon={" "}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  className={"step6"}
                  title2="Loan In Progress"
                  to="/b-loan-in-progress"
                  icon={" "}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  className={""}
                  title2="Agreements"
                  to="/b-agreements"
                  icon={" "}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  className={""}
                  title2="Transactions"
                  to="/b-transactions"
                  icon={" "}
                  selected={selected}
                  setSelected={setSelected}
                />
                {loanExist ? null : (
                  <Item
                    title="Free CBS Report"
                    icon={
                      <FolderSpecialIcon
                        style={{
                          fontSize: "18px",
                          marginLeft: "-3px",
                          color: "rgb(14, 90, 165)",
                        }}
                      />
                    }
                    icon2={
                      <FolderSpecialIcon
                        style={{
                          fontSize: "18px",
                          marginLeft: "-3px",
                          color: "#1871c9",
                        }}
                      />
                    }
                    selected={selected}
                    setSelected={setSelected}
                    to="/b-cbs-campaign"
                    blinking={true}
                  >
                    <Link
                      style={{ color: "inherit", textDecoration: "none" }}
                    />
                  </Item>
                )}

                <Item
                  title="Tools"
                  icon={
                    <img
                      src={loan_pressed}
                      alt=""
                      width={12}
                      height={12}
                      style={{ opacity: 0.3 }}
                    />
                  }
                  icon2={
                    <img
                      src={loan_ico}
                      alt=""
                      width={12}
                      height={12}
                      style={{ opacity: 0.3 }}
                    />
                  }
                  selected={selected}
                  setSelected={setSelected}
                  to="/b-coming-soon"
                >
                  <Link style={{ color: "inherit", textDecoration: "none" }} />
                </Item>

                <Item
                  title2="Document Vault"
                  icon={" "}
                  to="/b-coming-soon"
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title2="Instant Property Valuation"
                  icon={" "}
                  to="/b-coming-soon"
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
              {/* </SubMenu> */}

              {/* <Item
                title="Agreements"
                to="/b-agreements"
                icon={
                  <img src={agreements_pressed} alt="" width={12} height={12} />
                }
                icon2={
                  <img src={agreements_ico} alt="" width={12} height={12} />
                }
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Transactions"
                to="/b-transactions"
                icon={
                  <img
                    src={subscription_pressed}
                    alt=""
                    width={12}
                    height={12}
                  />
                }
                icon2={
                  <img src={subscription_ico} alt="" width={12} height={12} />
                }
                selected={selected}
                setSelected={setSelected}
              /> */}
            </Menu>
            <Menu
              mode="inline"
              defaultSelectedKeys={[selected]}
              selectedKeys={[selected]}
              style={{
                background: "#F1F6F9",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                height: "100%",
              }}
            >
              <div style={{ padding: "10px 35px 5px 15px" }}>
                <div
                  style={{
                    cursor: "pointer",
                    background: "rgb(255,255,255)",
                    borderRadius: "8px",
                    padding: "11px 15px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                  onClick={() => setFeedbackModal(true)}
                >
                  <ReviewsIcon
                    style={{
                      color: "rgba(0, 0, 0, 0.5)",
                      fontSize: 20,
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 700,
                      fontSize: "13px",
                      color: "rgba(0, 0, 0, 0.6)",
                      margin: "0px",
                    }}
                  >
                    Feedback
                  </p>
                </div>
              </div>
              <div style={{ padding: "10px 35px 5px 15px" }}>
                <div
                  style={{
                    cursor: "pointer",
                    background: "rgb(255,255,255)",
                    borderRadius: "8px",
                    padding: "11px 15px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                  onClick={() => setIsTourOpen(true)}
                >
                  <TourIcon
                    style={{
                      color: "rgba(0, 0, 0, 0.5)",
                      fontSize: 20,
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 700,
                      fontSize: "13px",
                      color: "rgba(0, 0, 0, 0.6)",
                      margin: "0px",
                    }}
                  >
                    Dashboard Tour
                  </p>
                </div>
              </div>
              <Item
                className="step2"
                title="Profile"
                to="/stg-b-profile"
                icon={
                  <img
                    className="odd_icon_setting"
                    src={profile_pressed}
                    alt=""
                    width={12}
                    height={12}
                  />
                }
                icon2={
                  <img
                    className="odd_icon_setting"
                    src={profile_ico}
                    alt=""
                    width={12}
                    height={12}
                  />
                }
                selected={selected}
                setSelected={setSelected}
              />
              <Item3
                title="Log Out"
                icon={
                  <LockIcon
                    className="colored_white_icon"
                    style={{ fontSize: "18px", marginLeft: "-3px" }}
                  />
                }
                icon2={
                  <LockIcon
                    style={{
                      fontSize: "18px",
                      marginLeft: "-3px",
                      color: "rgba(0,0,0,0.5)",
                    }}
                  />
                }
                to="#"
                onClick={(event) => handleLogout(event)}
                disabled={isLoading}
              />
            </Menu>
          </Box>
        </>
      ) : (
        <div>
          <BurgerMenu
            left
            width={"100%"}
            isOpen={menuIsOpen}
            customCrossIcon={false}
            customBurgerIcon={
              <Hamburger
                toggled={menuIsOpen}
                toggle={handleToggleMenu}
                size={24}
              />
            }
          >
            <div>
              <div
                style={{
                  marginTop: "20px",
                  fontSize: "35px",
                  fontWeight: "800",
                  color: "#142C44CC",
                }}
              >
                SmartLend
              </div>
              <div
                onClick={() => setMenuIsOpen(false)}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                <div
                  onClick={() => {
                    history.push("/b-dashboard");
                    window.scrollTo(0, 0);
                  }}
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    textDecoration: "none",
                    padding: "5px 0px",
                  }}
                >
                  Dashboard
                </div>
                <NavigateNextIcon />
              </div>
              <hr
                style={{
                  width: "100%",
                  height: "0.01rem",
                  color: "black",
                  margin: "5px 0px",
                }}
              />
              <div
                onClick={() => {
                  history.push("/b-messages");
                  window.scrollTo(0, 0);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    textDecoration: "none",
                    padding: "5px 0px",
                  }}
                >
                  Messages
                </div>
                <NavigateNextIcon />
              </div>
              <hr
                style={{
                  width: "100%",
                  height: "0.01rem",
                  color: "black",
                  margin: "5px 0px",
                }}
              />
              <div
                onClick={() => {
                  history.push("/b-apply-loan");
                  window.scrollTo(0, 0);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    textDecoration: "none",
                    padding: "5px 0px",
                  }}
                >
                  Loan
                </div>
                <NavigateNextIcon />
              </div>

              <div
                onClick={() => {
                  history.push("/b-apply-loan");
                  window.scrollTo(0, 0);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    textDecoration: "none",
                    padding: "5px 0px 5px 25px",
                  }}
                >
                  Apply Loan
                </div>
                <NavigateNextIcon />
              </div>

              <div
                onClick={() => {
                  history.push("/b-requested-loans");
                  window.scrollTo(0, 0);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    textDecoration: "none",
                    padding: "5px 0px 5px 25px",
                  }}
                >
                  Requested Loan
                </div>
                <NavigateNextIcon />
              </div>
              <div
                onClick={() => {
                  history.push("/b-accepted-loans");
                  window.scrollTo(0, 0);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    textDecoration: "none",
                    padding: "5px 0px 5px 25px",
                  }}
                >
                  Accepted Loan
                </div>
                <NavigateNextIcon />
              </div>
              <div
                onClick={() => {
                  history.push("/b-loan-in-progress");
                  window.scrollTo(0, 0);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    textDecoration: "none",
                    padding: "5px 0px 5px 25px",
                  }}
                >
                  Loan In Progress
                </div>
                <NavigateNextIcon />
              </div>
              <div
                onClick={() => {
                  history.push("/b-agreements");
                  window.scrollTo(0, 0);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    textDecoration: "none",
                    padding: "5px 0px 5px 25px",
                  }}
                >
                  Agreements
                </div>
                <NavigateNextIcon />
              </div>
              <div
                onClick={() => {
                  history.push("/b-transactions");
                  window.scrollTo(0, 0);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    textDecoration: "none",
                    padding: "5px 0px 5px 25px",
                  }}
                >
                  Transactions
                </div>
                <NavigateNextIcon />
              </div>
              <hr
                style={{
                  width: "100%",
                  height: "0.01rem",
                  color: "black",
                  margin: "5px 0px",
                }}
              />
              <div
                onClick={() => {
                  history.push("/b-cbs-campaign");
                  window.scrollTo(0, 0);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    textDecoration: "none",
                    padding: "5px 0px",
                  }}
                >
                  Free CBS Report
                </div>
                <NavigateNextIcon />
              </div>
              <hr
                style={{
                  width: "100%",
                  height: "0.01rem",
                  color: "black",
                  margin: "5px 0px",
                }}
              />
              <div
                onClick={() => {
                  history.push("/b-profile");
                  window.scrollTo(0, 0);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    textDecoration: "none",
                    padding: "5px 0px",
                  }}
                >
                  Profile
                </div>
                <NavigateNextIcon />
              </div>
              <div
                style={{
                  width: "90%",
                  textAlign: "center",
                  padding: "15px 20px",
                  background: "#51A22E",
                  color: "white",
                  marginTop: "35px",
                  cursor: "pointer",
                  borderRadius: "20px",
                }}
                onClick={(event) => {
                  handleLogout(event);
                  history.push("#");
                  window.scrollTo(0, 0);
                }}
              >
                Logout
              </div>
            </div>
          </BurgerMenu>
        </div>
      )}
    </>
  );
};
export default Sidebar;
