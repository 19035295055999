import { Button } from "antd";
import React, { useState, useEffect } from "react";

const TestButton = () => {
  const [buttonIsClicked, setButtonIsClicked] = useState(false);
  const [buttonIsClicked1, setButtonIsClicked1] = useState(false);
  const [buttonIsClicked2, setButtonIsClicked2] = useState(false);

  return (
    <div
      style={{
        width: "100%",
        height: "91vh",
        display: "flex",
        justifyContent: "space-betweeen",
        gap: "20px",
        color: "black",
      }}
    >
      <div
        style={{
          width: "97%",
          height: "100%",
          padding: "4px 0px 20px 20px",
        }}
      >
        <div
          style={{
            height: "100%",
            borderRadius: "10px",
            background:
              "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            padding: "15px",
          }}
        >
          <div>
            <div
              style={{
                color: "#142C44CC",
                fontSize: "18px",
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
              }}
            >
              Tets Below 3 buttons
            </div>
            <div>Just a test for mobile view</div>
          </div>
          <div
            style={{
              marginTop: "30px",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div>
              <div
                onClick={() => {
                  setButtonIsClicked(true);
                }}
                style={{
                  background: "#142C44CC",
                  color: "white",
                  padding: "8px 25px",
                  borderRadius: "20px",
                  cursor: "pointer",
                }}
              >
                test button div onclick
              </div>
              {buttonIsClicked ? <div>Button div works on mobile</div> : null}
            </div>
            <div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setButtonIsClicked1(true);
                }}
                style={{
                  background: "#142C44CC",
                  color: "white",
                  padding: "8px 25px",
                  borderRadius: "20px",
                  cursor: "pointer",
                }}
              >
                test button div onclick
              </button>
              {buttonIsClicked1 ? <div>Button div works on mobile</div> : null}
            </div>
            <div>
              <Button
                onClick={() => {
                  setButtonIsClicked2(true);
                }}
                style={{
                  background: "#142C44CC",
                  color: "white",
                  borderRadius: "20px",
                  cursor: "pointer",
                }}
              >
                test button Button onclick
              </Button>
              {buttonIsClicked2 ? <div>Button div works on mobile</div> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestButton;
