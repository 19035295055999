import React from "react";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Modal from "antd/es/modal/Modal";
import { Collapse, message } from "antd";
import { endOfDay, format } from "date-fns";
import { saveAs } from "file-saver";
import { addMonths, isValid } from "date-fns"; // Use date-fns for date manipulation
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const TestCharge = () => {
  const [data, setData] = useState([]);
  const [openedLoans, setOpenedLoans] = useState(null);
  const [checkboxChecked, setCheckboxChecked] = useState([]);
  const [createLoanModalIsOpen, setCreateLoanModalIsOpen] = useState(false);
  const [loanCreateData, setLoanCreateData] = useState({
    principal_name: "",
    amount: 0,
    tenure: 1,
    repayments: [],
    start_date: "",
    end_date: "",
  });
  const [chargeData, setChargeData] = useState({
    id: "",
    payerSwiftBic: "",
    payerSegment: "",
    payerName: "",
    precheckMinAmount: 1,
    precheckMaxAmount: 0,
    precheckCurrency: "SGD",
    endDate: "",
    returnUrl: "https://smart-lend.com/a-manual-giro",
    accountNumber: "",
  });

  const [clickedPaymentRow, setClickedPaymentRow] = useState(null);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  const updateLoanPaymentStatus = async (datatopush) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/giro/updateManualGiro`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify(datatopush),
        }
      );
      const json = await response.json();
      console.log(json);
      if (response.status === 200) {
        message.success("Giro payment succesfully updated");
        const updatingData = [...data];
        updatingData[openedLoans].repayments = JSON.parse(
          datatopush.repayments
        );
        setData(updatingData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const creategiroauth = async () => {
    console.log(chargeData);
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/giro/creategiroauth`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify({
            ...chargeData,
            precheckMaxAmount: parseFloat(
              (chargeData.precheckMaxAmount * 100).toFixed(0)
            ),
          }),
        }
      );
      const json = await response.json();
      console.log(json);
      if (response.status === 201) {
        message.success(json.message);
        if (json?.api_response?.authorizeUrl) {
          Cookies.set(
            "ddtokenauth",
            JSON.stringify({
              ddid: json.api_response.id,
              loan_id: chargeData.loan_id,
              lender_id: chargeData.lender_id,
              repay_id: chargeData.repay_id,
            })
          );
          window.location.href = json?.api_response?.authorizeUrl;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createManualGiro = async () => {
    try {
      const datatosend = {
        principal_name: loanCreateData.principal_name,
        amount: loanCreateData.amount,
        tenure: loanCreateData.tenure,
        repayments: JSON.stringify(loanCreateData.repayments),
        start_date: loanCreateData.start_date,
        end_date: loanCreateData.end_date,
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/giro/createManualGiro",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datatosend),
        }
      );
      if (response.status === 201) {
        const json = await response.json();
        const datatopush = {
          ...json.manualGiroData,
          repayments: JSON.parse(json.manualGiroData.repayments),
        };
        setData([...data, datatopush]);
        setCreateLoanModalIsOpen(false);
        setLoanCreateData({
          principal_name: "",
          amount: 0,
          tenure: 1,
          repayments: [],
          start_date: "",
          end_date: "",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const checkGiroAuth = async (token) => {
    console.log(chargeData);
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/giro/checkGiroAuth`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ddid: token.ddid }),
        }
      );
      if (response.ok) {
        Cookies.remove("ddtokenauth");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const ddtoken = Cookies.get("ddtokenauth");
    if (ddtoken) {
      const ddData = JSON.parse(ddtoken);
      checkGiroAuth(ddData);
    }
  }, []);

  const geAllManualGiro = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/giro/geAllManualGiro",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        const json = await response.json();

        // Parse the repayments field in each item
        const parsedData = json.data.map((item) => ({
          ...item,
          repayments: JSON.parse(item.repayments), // Parse the repayments field
        }));

        setData(parsedData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    geAllManualGiro();
  }, []);

  useEffect(() => {
    if (
      loanCreateData.amount &&
      parseFloat(loanCreateData.amount) > 0 &&
      loanCreateData.tenure &&
      loanCreateData.start_date
    ) {
      const generateRepayments = (startDate, amount, tenure) => {
        const repayments = [];
        const monthlyAmount = parseFloat(amount);

        for (let i = 0; i < tenure; i++) {
          // Create a date object for the start date
          const baseDate = new Date(startDate);

          // Add months while maintaining the same day or the last valid day of the month
          const paymentDate = addMonths(baseDate, i);
          const lastDayOfMonth = new Date(
            paymentDate.getFullYear(),
            paymentDate.getMonth() + 1,
            0
          ).getDate(); // Get the last day of the current month
          const adjustedDate = Math.min(baseDate.getDate(), lastDayOfMonth);

          // Set the adjusted date to paymentDate
          paymentDate.setDate(adjustedDate);

          // Add the payment details to the array
          repayments.push({
            amount: monthlyAmount.toFixed(2), // Round to two decimal places
            payment_date: paymentDate.toISOString().split("T")[0], // Format to YYYY-MM-DD
            status: 0,
          });
        }

        return repayments;
      };

      // Generate repayments
      const repayments = generateRepayments(
        loanCreateData.start_date,
        loanCreateData.amount,
        loanCreateData.tenure
      );

      // Calculate the end date and add 7 days
      const calculatedEndDate =
        repayments[repayments.length - 1]?.payment_date || "";
      const adjustedEndDate = calculatedEndDate
        ? new Date(calculatedEndDate)
        : null;

      if (adjustedEndDate) {
        adjustedEndDate.setDate(adjustedEndDate.getDate() + 7); // Add 7 days
      }

      // Update the loan data
      setLoanCreateData((prevData) => ({
        ...prevData,
        repayments: repayments,
        end_date: adjustedEndDate
          ? adjustedEndDate.toISOString().split("T")[0] // Format as YYYY-MM-DD
          : "",
      }));
      console.log(loanCreateData);
    }
  }, [loanCreateData.amount, loanCreateData.tenure, loanCreateData.start_date]);

  const formatDate = (dateString) => {
    if (!dateString) return "-"; // Return a default value if the date string is not provided
    const date = new Date(dateString); // Create a Date object from the date string
    if (isValid(dateString)) {
      const formattedDate = format(date, "MMM dd, yyyy"); // Use date-fns to format the date in the desired format

      return formattedDate;
    } else {
      return "-";
    }
  };

  function generateDate() {
    const today = new Date(); // Get the current date
    const day = String(today.getDate()).padStart(2, "0"); // Get the day and ensure 2 digits
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Get the month and ensure 2 digits
    const year = today.getFullYear(); // Get the year

    return `${day}${month}${year}`; // Concatenate into ddmmyyyy
  }

  function generateDate2() {
    const today = new Date(); // Get the current date
    const day = String(today.getDate()).padStart(2, "0"); // Get the day and ensure 2 digits
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Get the month and ensure 2 digits
    const year = today.getFullYear(); // Get the year

    return `${year}${month}${day}`; // Concatenate into ddmmyyyy
  }

  const handleCheckboxChange = (index) => {
    setCheckboxChecked((prev) => {
      if (prev.includes(index)) {
        // Remove index if already checked
        return prev.filter((i) => i !== index);
      } else {
        // Add index if not already checked
        return [...prev, index];
      }
    });
  };

  function generateRandomId() {
    return Math.floor(100 + Math.random() * 900); // Generate a random number between 100 and 999
  }
  const headerTemplate = [
    { text: "20", length: 2, align: "left", description: "Collection code" },
    {
      text: `${generateRandomId()}`,
      length: 3,
      align: "left",
      description: "Batch number random id",
    },
    {
      text: generateDate2(),
      length: 8,
      align: "left",
      description: "Date of generating document YYYYMMDD",
    },
    {
      text: "OCBCSGSGXXX",
      length: 11,
      align: "left",
      description: "Originating bank code",
    },
    {
      text: "602180564001",
      length: 34,
      align: "left",
      description: "Account number",
    },
    { text: "", length: 3, align: "left", description: "Blank filler" },
    {
      text: "On Behalf of SmartL",
      length: 20,
      align: "left",
      description: "On behalf of",
    },
    { text: "", length: 120, align: "left", description: "Blank filler" },
    { text: "", length: 4, align: "left", description: "Blank filler" },
    {
      text: "FAST",
      length: 4,
      align: "left",
      description: "Clearing using GIRO of FAST",
    },
    { text: "", length: 16, align: "left", description: "Blank filler" },
    {
      text: generateDate(),
      length: 8,
      align: "left",
      description: "Date Transaction should happen DDMMYYYY",
    },
    {
      text: "",
      length: 4,
      align: "left",
      description: "Leave blank for immediate",
    },
    { text: "", length: 1, align: "left", description: "Batch booking filler" },
    { text: "", length: 762, align: "left", description: "Blank filler" },
  ];
  const getrepaymentindex = (id) => {
    // Find the loan index
    const loanIndex = data.findIndex((dat) => dat.id === id);

    if (loanIndex > -1) {
      // Get the repayments array for the loan
      const repayments = data[loanIndex].repayments;

      // Find the earliest repayment with status === 0
      let earliestIndex = -1;
      let earliestDate = null;

      repayments.forEach((repayment, index) => {
        if (repayment.status === 0) {
          const paymentDate = new Date(repayment.payment_date);
          if (!earliestDate || paymentDate < earliestDate) {
            earliestDate = paymentDate;
            earliestIndex = index;
          }
        }
      });

      // Return the index + 1 of the earliest repayment with status === 0
      return earliestIndex > -1 ? earliestIndex + 1 : -1; // Return -1 if none found
    }

    // Return -1 if the loan with the given id is not found
    return -1;
  };
  const generateFixedWidthTxt = () => {
    let secondLineOnwards = [];
    const dataAvailableForGen = data.filter(
      (dat) =>
        dat.status === 1 &&
        dat.repayments.filter((repa) => repa.status === 0).length > 0
    );
    checkboxChecked.map((key, index) => {
      const dataToPush = [
        {
          text: dataAvailableForGen?.[key]?.swift_bic,
          length: 11,
          align: "left",
          description: "Bank Code",
        },
        {
          text: dataAvailableForGen?.[key]?.acc_number,
          length: 34,
          align: "left",
          description: "Employee account number",
        },
        {
          text: dataAvailableForGen?.[key]?.name,
          length: 35,
          align: "left",
          description: "Employee name",
        },
        {
          text: "",
          length: 105,
          align: "left",
          description:
            "Blank filler for name as FAST only allow up till 35 char",
        },
        { text: "SGD", length: 3, align: "left", description: "Currency" },
        {
          text: String(dataAvailableForGen?.[key]?.amount * 100)
            .replace(".", "")
            .padStart(17, "0"),
          length: 17,
          align: "left",
          description: "Currency in cents",
        },
        {
          text: `SML ${getrepaymentindex(dataAvailableForGen?.[key]?.id)}/${
            dataAvailableForGen?.[key]?.tenure
          } repayment`,
          length: 35,
          align: "left",
          description: "Payment Details",
        },
        {
          text: "COLL",
          length: 4,
          align: "left",
          description: "Collection code",
        },
        {
          text: dataAvailableForGen?.[key]?.ref_number,
          length: 35,
          align: "right",
          description: "Debtor Reference",
        },
        { text: "", length: 140, align: "left", description: "Blank filler" },
        { text: "", length: 140, align: "left", description: "Blank filler" },
        {
          text: "",
          length: 1,
          align: "left",
          description: "Blank filler (Send remitance via)",
        },
        {
          text: "",
          length: 255,
          align: "left",
          description: "Blank filler (Send details only if invoice involved)",
        },
        {
          text: "",
          length: 12,
          align: "left",
          description: "Blank filler (Proxy type only for paynow transactions)",
        },
        {
          text: "",
          length: 140,
          align: "left",
          description:
            "Blank filler (Proxy value only for paynow transactions)",
        },
        { text: "", length: 33, align: "left", description: "Blank filler" },
      ];
      console.log(dataToPush);
      secondLineOnwards.push(dataToPush);
    });

    console.log([[...headerTemplate], ...secondLineOnwards]);

    const totalData = [[...headerTemplate], ...secondLineOnwards];

    const lines = totalData.map(
      (line) =>
        line
          .map((col) => {
            const { text, length, align } = col;

            // Truncate if text exceeds length
            const truncated =
              text.length > length ? text.slice(0, length) : text;

            // Pad based on alignment
            if (align === "left") {
              return truncated.padEnd(length, " ");
            } else if (align === "right") {
              return truncated.padStart(length, " ");
            } else {
              throw new Error("Invalid alignment value");
            }
          })
          .join("") // Combine all columns into a single line
    );

    // Combine all lines with newline characters
    const result = lines.join("\n");
    const formatDateForFilename = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
    };
    // Create and save the .txt file
    const blob = new Blob([result], { type: "text/plain;charset=utf-8" });
    saveAs(blob, `giro_${formatDateForFilename(new Date())}.txt`);
    message.success("Giro document created.");
  };

  return (
    <div
      style={{
        width: "100%",
        height: "91vh",
        display: "flex",
        justifyContent: "space-betweeen",
        gap: "20px",
        color: "black",
      }}
    >
      <Modal
        width={window.innerWidth > 1200 ? "40vw" : "80vw"}
        footer={null}
        open={createLoanModalIsOpen}
        zIndex={800}
        onCancel={() => {
          setCreateLoanModalIsOpen(false);
          setLoanCreateData({
            principal_name: "",
            amount: 0,
            tenure: 1,
            repayments: [],
            start_date: "",
            end_date: "",
          });
        }}
      >
        <div
          style={{
            color: "#142C44CC",
            fontSize: "18px",
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
          }}
        >
          Create new payment schedule
        </div>
        <div style={{ color: "grey", fontSize: "10px" }}>
          Fill in the below details, and a payment schedule will be generated,
          once you've confirmed that the payment schedule is what you're looking
          for, you may proceed to hit confirm.
        </div>
        <div style={{ marginTop: "15px" }}>
          <div style={{ color: "grey", fontSize: "12px" }}>Payee Name</div>
          <input
            style={{
              width: "80%",
              padding: "4px 8px",
              borderRadius: "5px",
              border: "1px solid lightgrey",
              outline: "none",
            }}
            value={loanCreateData.principal_name}
            onChange={(e) => {
              setLoanCreateData({
                ...loanCreateData,
                principal_name: e.target.value,
              });
            }}
          />
        </div>
        <div style={{ marginTop: "8px" }}>
          <div style={{ color: "grey", fontSize: "12px" }}>
            Monthly Payable Amount
          </div>
          <input
            style={{
              width: "80%",
              padding: "4px 8px",
              borderRadius: "5px",
              border: "1px solid lightgrey",
              outline: "none",
            }}
            value={loanCreateData.amount}
            onChange={(e) => {
              setLoanCreateData({
                ...loanCreateData,
                amount: e.target.value,
              });
            }}
          />
        </div>
        <div style={{ marginTop: "8px" }}>
          <div style={{ color: "grey", fontSize: "12px" }}>Payment Tenure</div>
          <input
            style={{
              width: "80%",
              padding: "4px 8px",
              borderRadius: "5px",
              border: "1px solid lightgrey",
              outline: "none",
            }}
            value={loanCreateData.tenure}
            type="number"
            onChange={(e) => {
              setLoanCreateData({
                ...loanCreateData,
                tenure: e.target.value,
              });
            }}
          />
        </div>
        <div style={{ marginTop: "8px" }}>
          <div style={{ color: "grey", fontSize: "12px" }}>
            First Payment Date
          </div>
          <input
            style={{
              width: "80%",
              padding: "4px 8px",
              borderRadius: "5px",
              border: "1px solid lightgrey",
              outline: "none",
            }}
            value={loanCreateData.start_date}
            type="date"
            onChange={(e) => {
              setLoanCreateData({
                ...loanCreateData,
                start_date: e.target.value,
              });
            }}
          />
        </div>

        {loanCreateData.repayments.length > 0 ? (
          <div style={{ marginTop: "20px" }}>
            <div
              style={{
                color: "#142C44CC",
                fontSize: "18px",
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
              }}
            >
              Payment Schedule
            </div>
            <div style={{ color: "grey", fontSize: "10px" }}>
              Below is the payment schedule to allow you to know when is the
              payment date and the amount to be paid.
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                borderRadius: "7px",
                overflow: "hidden",
                marginTop: "12px",
              }}
            >
              {loanCreateData.repayments.map((dat, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom:
                      loanCreateData.repayments.length - 1 !== index
                        ? "1px solid lightgrey"
                        : "",
                  }}
                >
                  <div style={{ padding: "4px 14px" }}>
                    {parseFloat(dat?.amount)?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "SGD",
                    })}
                  </div>
                  <div style={{ padding: "4px 14px" }}>
                    {formatDate(new Date(dat?.payment_date))}
                  </div>
                </div>
              ))}
            </div>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <div
                onClick={() => {
                  createManualGiro();
                }}
                className="mui_button"
                style={{
                  padding: "8px 25px",
                  borderRadius: "8px",
                  color: "white",
                  backgroundColor: "rgb(17, 92, 166)",
                }}
              >
                Confirm
              </div>
            </div>
          </div>
        ) : null}
      </Modal>

      <Modal
        width={window.innerWidth > 1200 ? "40vw" : "80vw"}
        footer={null}
        open={openedLoans !== null}
        zIndex={800}
        onCancel={() => {
          setOpenedLoans(null);
          setClickedPaymentRow(null);
          setChargeData({
            id: "",
            payerSwiftBic: "",
            payerSegment: "",
            payerName: "",
            precheckMinAmount: 1,
            precheckMaxAmount: 0,
            precheckCurrency: "SGD",
            endDate: "",
            returnUrl: "https://smart-lend.com/a-manual-giro",
            accountNumber: "",
            lender_id: "",
            loan_id: "",
            repay_id: "",
          });
        }}
      >
        {openedLoans !== null ? (
          <div>
            <div
              style={{
                color: "#142C44CC",
                fontSize: "18px",
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
              }}
            >
              Giro Schedule and Setup
            </div>
            <div style={{ color: "grey", fontSize: "10px" }}>
              Giro Status:{" "}
              <span
                style={{ color: data[openedLoans].status ? "green" : "red" }}
              >
                {data[openedLoans].status ? "Complete" : "Not configured"}
              </span>
            </div>
            {!data[openedLoans].status ? (
              <div style={{ marginTop: "15px" }}>
                <div style={{}}>
                  <div style={{ width: "100%" }}>
                    <div>Select Bank Account Type</div>
                    <select
                      value={chargeData.payerSegment}
                      onChange={(e) => {
                        setChargeData({
                          ...chargeData,
                          payerSegment: e.target.value,
                        });
                        console.log(chargeData);
                      }}
                      style={{
                        width: "50%",
                        borderRadius: "7px",
                        outline: "none",
                        border: "1px solid lightgrey",
                        padding: "5px 8px",
                      }}
                    >
                      <option value={""}>Bank Account Type</option>
                      {/* <option value={"RETAIL"}>Retail</option> */}
                      <option value={"CORPORATE"}>Corporate</option>
                    </select>
                  </div>
                  {chargeData.payerSegment === "RETAIL" ? (
                    <div style={{ width: "100%", marginTop: "10px" }}>
                      <div>Select Bank</div>
                      <select
                        value={chargeData.payerSwiftBic}
                        onChange={(e) => {
                          setChargeData({
                            ...chargeData,
                            payerSwiftBic: e.target.value,
                          });
                          console.log(chargeData);
                        }}
                        style={{
                          width: "50%",
                          borderRadius: "7px",
                          outline: "none",
                          border: "1px solid lightgrey",
                          padding: "5px 8px",
                        }}
                      >
                        <option value={""}>Select Bank</option>
                        <option value={"BKCHSGSGXXX"}>Bank of China</option>
                        <option value={"DBSSSGSGXXX"}>DBS Bank</option>
                        <option value={"HSBCSGS2XXX"}>HSBC Bank</option>
                        <option value={"ICBKSGSGXXX"}>
                          Industrial & Commercial Bank of China
                        </option>
                        <option value={"MBBESGS2XXX"}>Maybank</option>
                        <option value={"OCBCSGSGXXX"}>
                          Oversea-Chinese Banking Corporation
                        </option>
                        <option value={"SCBLSG22XXX"}>
                          Standard Chartered Bank
                        </option>
                        <option value={"UOVBSGSGXXX"}>
                          United Overseas Bank
                        </option>
                      </select>
                    </div>
                  ) : null}
                  {chargeData.payerSegment === "CORPORATE" ? (
                    <div style={{ width: "100%", marginTop: "10px" }}>
                      <div>Select Bank</div>
                      <select
                        value={chargeData.payerSwiftBic}
                        onChange={(e) => {
                          setChargeData({
                            ...chargeData,
                            payerSwiftBic: e.target.value,
                          });
                          console.log(chargeData);
                        }}
                        style={{
                          width: "50%",
                          borderRadius: "7px",
                          outline: "none",
                          border: "1px solid lightgrey",
                          padding: "5px 8px",
                        }}
                      >
                        <option value={""}>Select Bank</option>
                        <option value={"BKCHSGSGXXX"}>Bank of China</option>
                        <option value={"DBSSSGSGXXX"}>DBS Bank</option>
                        <option value={"MBBESGS2XXX"}>Maybank</option>
                        <option value={"OCBCSGSGXXX"}>
                          Oversea-Chinese Banking Corporation
                        </option>
                        <option value={"UOVBSGSGXXX"}>
                          United Overseas Bank
                        </option>
                      </select>
                    </div>
                  ) : null}
                  <div style={{ width: "100%", marginTop: "10px" }}>
                    <div>Account Holder Name</div>
                    <input
                      placeholder="Account Holder Name"
                      value={chargeData.payerName}
                      onChange={(e) => {
                        setChargeData({
                          ...chargeData,
                          payerName: e.target.value,
                        });
                        console.log(chargeData);
                      }}
                      style={{
                        width: "50%",
                        borderRadius: "7px",
                        outline: "none",
                        border: "1px solid lightgrey",
                        padding: "5px 8px",
                      }}
                    />
                  </div>
                  <div style={{ width: "100%", marginTop: "10px" }}>
                    <div>Bank Account Number</div>
                    <input
                      placeholder="Bank Account Number"
                      value={chargeData.accountNumber}
                      onChange={(e) => {
                        setChargeData({
                          ...chargeData,
                          accountNumber: e.target.value,
                        });
                        console.log(chargeData);
                      }}
                      style={{
                        width: "50%",
                        borderRadius: "7px",
                        outline: "none",
                        border: "1px solid lightgrey",
                        padding: "5px 8px",
                      }}
                    />
                  </div>
                  <div style={{ width: "100%", marginTop: "10px" }}>
                    <div>Payment End Date</div>
                    <input
                      type="date"
                      disabled
                      value={chargeData.endDate}
                      onChange={(e) => {
                        setChargeData({
                          ...chargeData,
                          endDate: e.target.value,
                        });
                        console.log(chargeData);
                      }}
                      style={{
                        width: "50%",
                        borderRadius: "7px",
                        outline: "none",
                        border: "1px solid lightgrey",
                        padding: "5px 8px",
                      }}
                    />
                  </div>
                  <div style={{ width: "100%", marginTop: "10px" }}>
                    <div>Charge Limit</div>
                    <input
                      type="number"
                      value={chargeData.precheckMaxAmount}
                      min={1}
                      disabled
                      max={1000000}
                      onChange={(e) => {
                        setChargeData({
                          ...chargeData,
                          precheckMaxAmount: parseFloat(e.target.value),
                        });
                        console.log(chargeData);
                      }}
                      style={{
                        width: "50%",
                        borderRadius: "7px",
                        outline: "none",
                        border: "1px solid lightgrey",
                        padding: "5px 8px",
                      }}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <div
                      onClick={() => {
                        creategiroauth();
                      }}
                      style={{
                        background: "rgb(17, 92, 166)",
                        color: "white",
                        padding: "7px 30px",
                        border: "none",
                        marginTop: "10px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                    >
                      Authorize
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    borderRadius: "7px",
                    overflow: "hidden",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  {data[openedLoans]?.repayments?.map((repa, index) => (
                    <div
                      onClick={() => setClickedPaymentRow(index)}
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "5px 20px",
                        background:
                          repa.status === 0 &&
                          new Date(repa.payment_date) < new Date()
                            ? "red"
                            : index % 2
                            ? "rgba(17, 92, 166, 0.1)"
                            : "white",
                        color:
                          repa.status === 0 &&
                          new Date(repa.payment_date) < new Date()
                            ? "white"
                            : "#142C44CC",
                        fontSize: 12,
                        cursor: "pointer",
                      }}
                    >
                      <div>
                        {parseFloat(repa.amount)?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                      </div>
                      <div>{formatDate(new Date(repa.payment_date))}</div>
                      <div>
                        {repa.status
                          ? "paid"
                          : new Date(repa.payment_date) < new Date()
                          ? "late"
                          : "unpaid"}
                      </div>
                    </div>
                  ))}
                </div>
                {clickedPaymentRow !== null ? (
                  <div style={{ marginTop: "20px" }}>
                    <div
                      style={{
                        color: "#142C44CC",
                        fontSize: "18px",
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "800",
                      }}
                    >
                      Payment {clickedPaymentRow + 1} of{" "}
                      {data[openedLoans]?.repayments.length}
                    </div>

                    <div style={{ color: "grey", fontSize: "10px" }}>
                      Please confirm that you would like to mark the selected
                      payment as complete.
                    </div>

                    <div style={{ display: "flex", marginTop: "15px" }}>
                      <div
                        onClick={() => {
                          const updatedRepayments = [
                            ...data[openedLoans].repayments,
                          ];
                          updatedRepayments[clickedPaymentRow].status = 1;
                          const dataToPush = {
                            id: data[openedLoans].id,
                            repayments: JSON.stringify(updatedRepayments),
                          };
                          updateLoanPaymentStatus(dataToPush);
                        }}
                        className="mui_button"
                        style={{
                          padding: "8px 25px",
                          borderRadius: "8px",
                          color: "white",
                          backgroundColor: "rgb(17, 92, 166)",
                        }}
                      >
                        Complete
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        ) : null}
      </Modal>
      <div
        style={{
          width: "97%",
          height: "100%",
          padding: "4px 0px 20px 20px",
        }}
      >
        <div
          style={{
            height: "100%",
            borderRadius: "10px",
            background:
              "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            padding: "15px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <div
                style={{
                  color: "#142C44CC",
                  fontSize: "18px",
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                }}
              >
                Manual Giro Setup
              </div>
              <div>
                To create a new Giro setup, hit the create new button to begin.
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                onClick={() => setCreateLoanModalIsOpen(true)}
                className="mui_button"
                style={{
                  padding: "8px 25px",
                  borderRadius: "8px",
                  color: "white",
                  backgroundColor: "rgb(17, 92, 166)",
                }}
              >
                Create New
              </div>
            </div>
          </div>

          <div
            style={{
              background: "rgba(256,256,256,0.4)",
              borderRadius: "8px",
              width: "100%",
              height: "35vh",
              color: "rgb(80,80,80)",
              overflow: "hidden",
              overflowY: "auto",
              marginTop: "15px",
              boxShadow: "0px 0px 10px #142C441A",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5px 8px",
                background: "rgba(17, 92, 166, 0.1)",
                color: "#142C44CC",
                fontSize: "20px",
                fontFamily: "Manrope,sans-serif",
                fontWeight: 800,
                fontSize: 14,
                gap: "1%",
              }}
            >
              <div style={{ width: "35%" }}>Principal Name</div>
              <div style={{ width: "15%" }}>Loan start date</div>
              <div style={{ width: "15%" }}>Monthly-installment</div>
              <div style={{ width: "15%" }}>Current due-date</div>
            </div>
            {data.map((pay, index) => (
              <div
                key={index}
                onClick={() => {
                  setOpenedLoans(index);
                  setChargeData({
                    ...chargeData,
                    id: pay.id,
                    endDate: pay.end_date,
                    precheckMaxAmount: pay.amount,
                  });
                }}
                className="row_click"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "12px 8px",
                  background:
                    new Date(
                      pay.repayments.filter(
                        (repay) => repay.status === 0
                      )?.[0]?.payment_date
                    ) < new Date()
                      ? "red"
                      : index % 2
                      ? "rgba(17, 92, 166, 0.1)"
                      : "white",
                  color:
                    new Date(
                      pay.repayments.filter(
                        (repay) => repay.status === 0
                      )?.[0]?.payment_date
                    ) < new Date()
                      ? "white"
                      : "#142C44CC",
                  fontFamily: "Manrope,sans-serif",
                  fontWeight: 500,
                  fontSize: 12,
                  cursor: "pointer",
                  gap: "1%",
                }}
              >
                <div
                  style={{
                    width: "35%",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {pay?.principal_name}
                </div>
                <div style={{ width: "15%" }}>
                  {formatDate(new Date(pay?.start_date))}
                </div>
                <div style={{ width: "15%" }}>
                  {parseFloat(pay?.amount)?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "SGD",
                  })}
                </div>
                <div style={{ width: "15%", color: !pay?.repayments?.filter((repay) => repay.status === 0)
                    .length > 0 ? "green" : "" }}>
                  {pay?.repayments?.filter((repay) => repay.status === 0)
                    .length > 0
                    ? formatDate(
                        new Date(
                          pay?.repayments?.filter(
                            (repay) => repay.status === 0
                          )?.[0]?.payment_date
                        )
                      )
                    : "Payment Completed"}
                </div>
              </div>
            ))}
          </div>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  color: "#142C44CC",
                  fontSize: "20px",
                  fontFamily: "Manrope,sans-serif",
                  fontWeight: 800,
                }}
              >
                Pending E-Giro Transactions
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  if (checkboxChecked.length < 1) {
                    message.error("No transaction selected.");
                  } else {
                    generateFixedWidthTxt();
                  }
                }}
                style={{
                  background: "rgb(17, 92, 166)",
                  color: "white",
                  padding: "7px 30px",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Export GIRO
              </div>
            </div>
            <div
              style={{
                background: "rgba(256,256,256,0.4)",
                borderRadius: "8px",
                width: "100%",
                height: "35vh",
                color: "rgb(80,80,80)",
                overflow: "hidden",
                overflowY: "auto",
                marginTop: "15px",
                boxShadow: "0px 0px 10px #142C441A",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px 8px",
                  background: "rgba(17, 92, 166, 0.1)",
                  color: "#142C44CC",
                  fontSize: "20px",
                  fontFamily: "Manrope,sans-serif",
                  fontWeight: 800,
                  fontSize: 14,
                }}
              >
                <div style={{ width: "50%" }}>Entity Name</div>
                <div style={{ width: "15%" }}>Repayment Amount</div>
                <div style={{ width: "15%" }}>Payment Date</div>
              </div>
              {data
                .filter(
                  (dat) =>
                    dat.status === 1 &&
                    dat.repayments.filter((repa) => repa.status === 0).length >
                      0
                )
                .map((pay, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "12px 8px",
                      background:
                        index % 2 ? "rgba(17, 92, 166, 0.1)" : "white",
                      color: "#142C44CC",
                      fontFamily: "Manrope,sans-serif",
                      fontWeight: 500,
                      fontSize: 12,
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        style={{
                          width: "15px",
                          height: "15px",
                          marginBottom: "0px",
                        }}
                        checked={checkboxChecked.includes(index)}
                        onChange={() => handleCheckboxChange(index)}
                      />
                      {pay?.principal_name}
                    </div>
                    <div
                      style={{
                        width: "15%",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {parseFloat(pay?.amount)?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "SGD",
                      })}
                    </div>
                    <div
                      style={{
                        width: "15%",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {formatDate(
                        new Date(
                          pay?.repayments?.filter(
                            (repa) => repa.status === 0
                          )?.[0].payment_date
                        )
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestCharge;
