import React, { useEffect, useState } from "react";
import cbsScreenshot from "../../assets/img/CBS4.jpeg";
import uploadScreenshot from "../../assets/img/uploadcbsss.png";
import singpassmobilepage from "../../assets/img/Singpasslogin-screenshot.png";
import Cookies from "js-cookie";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { Button, Upload, message, Modal } from "antd";
import pdfToText from "react-pdftotext";
import { UploadOutlined } from "@ant-design/icons";
import { UserOutlined, QuestionOutlined } from "@ant-design/icons";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import logo from "../../assets/img/SL-01.png";
import boldFont from "../../assets/font/Poppins-SemiBold.ttf";
import cbsImage from "../smart-lend-frontend/src/img/CBSlogo.png";
import SVGICON from "../../assets/img/fssvg.svg";
import DoneIcon from "@mui/icons-material/Done";
import SlLogo from "../../assets/img/SL-01.png";
import { useHistory } from "react-router-dom";

Font.register({
  family: "Poppins",
  fonts: [
    { src: boldFont, fontWeight: 700 }, // Bold (700)
  ],
});

const CbsCampagin = () => {
  const [step, setStep] = useState(1);
  const [freeCbsCode, setFreeCbsCode] = useState("");
  const [fileList, setFileList] = useState([]);
  const [creditReportFile, setCreditReportFile] = useState([]);
  const [creditFile, setCreditFile] = useState(null);
  const [stepModal, setStepModal] = useState(false);

  const [CBSImpLoan, setCBSImpLoan] = useState(null);
  const [defaults, setDefaults] = useState(null);
  const [bankcruptcyProceedings, setBankcruptcyProceedings] = useState(null);
  const [overdueBalance, setOverdueBalance] = useState(null);
  const [monthlyCommitment, setMonthlyCommitment] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [enquiryDate, setEnquiryDate] = useState(null);
  const [chartMaxValue, setChartMaxValue] = useState(null);
  const [
    cBSImpLoanSupplementaryMonthlyInstallment,
    setCBSImpLoanSupplementaryMonthlyInstallment,
  ] = useState(null);

  const [userDetails, setUserDetails] = useState(null);
  const [allCBSData, setAllCBSData] = useState([]);
  const [badgeVerified, setBadgeVerified] = useState(false);
  const [needOtherAuthoriser, setNeedOtherAuthoriser] = useState(false);
  const [otherAuthoriserDetails, setOtherAuthoriserDetails] = useState(null);
  const [liveUserData, setLiveUserData] = useState(null);
  const [keyman, setKeyman] = useState("");
  const [dataSingpassModal, setDataSingpassModal] = useState(false);
  const [singpassData, setSingpassData] = useState(null);
  const [viewingTab, setViewingTab] = useState(0);
  const [combinedSingpassData, setCombinedSingpassData] = useState([]);
  const [openDataIndex, setOpenDataIndex] = useState(null);
  const [eligibleForFundingSocietyLoan, setEligibleForFundingSocietyLoan] =
    useState(false);

  const history = useHistory();

  useEffect(() => {
    if (Cookies.get("signupthroughcbs")) {
      Cookies.remove("signupthroughcbs");
    }
  }, []);
  const availableTab = [
    { name: "Personal Information" },
    { name: "Corporate Information" },
    { name: "NOA History" },
    { name: "Capitals" },
    { name: "Financials" },
    { name: "Shareholders" },
    { name: "Borrower Report" },
  ];

  const getShareholderAddress = (data) => {
    const allSingpassData = [singpassData];
    if (otherAuthoriserDetails !== null && otherAuthoriserDetails.length > 0) {
      for (const single of otherAuthoriserDetails) {
        allSingpassData.push(JSON.parse(single.singpass_data));
      }
      const toPush = allSingpassData.filter(
        (sd) => sd?.person?.name?.value === data
      );
      // console.log(toPush);
      return toPush;
    } else {
      return allSingpassData;
    }
  };
  const singpassDetails = async (
    user,
    other_authorization,
    loan_id,
    keyman
  ) => {
    try {
      setKeyman(keyman);
      const response = await fetch(
        `https://www.smart-lend.com/api/user/getBorrowerSingppass?user_id=${user}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const json = await response.json();
      let allSingpassData = [json.singpass_data];
      setDataSingpassModal(true);
      if (other_authorization) {
        console.log("need other authorizer");
        setNeedOtherAuthoriser(true);
        try {
          const response2 = await fetch(
            `https://www.smart-lend.com/api/loanDetails/getLoanAuthorisers`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("token")}`,
              },
              body: JSON.stringify({ loan_id: loan_id }),
            }
          );

          const json2 = await response2.json();
          setOtherAuthoriserDetails(json2.authorisers);
          console.log(JSON.parse(json2.authorisers[0].singpass_data));
          console.log(json.singpass_data);
          setLiveUserData(json.singpass_data);
          console.log(json2.authorisers);

          for (const single of json2.authorisers) {
            allSingpassData.push(JSON.parse(single.singpass_data));
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        console.log("Doesnt need other authoriser");
        setLiveUserData(json.singpass_data);
        setNeedOtherAuthoriser(false);
        setOtherAuthoriserDetails(null);
      }
      if (json.singpass_data) {
        setSingpassData(json.singpass_data);
        console.log(allSingpassData);
        setCombinedSingpassData(allSingpassData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (userDetails) {
      setBadgeVerified(userDetails?.badge_verified || 0);

      const dataToPush = [
        {
          name: userDetails.singpass_data?.person?.name?.value,
          cbs_data: JSON.parse(userDetails.credit_report_data),
        },
      ];
      setAllCBSData(dataToPush);
      singpassDetails(userDetails.user_id);
    }
  }, [userDetails]);

  const getUserDetails = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/profile/getUserDetails",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        setUserDetails(json.User);
        if (json.User.credit_report_data) {
          setStep(4);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (userDetails) {
      let eligibleForFundingSociety = true;
      const creditReportData = JSON.parse(userDetails.credit_report_data);
      const singpassData = JSON.parse(userDetails.singpass_data);
      // console.log(creditReportData, singpassData);

      const registrationDateString =
        singpassData?.entity?.["basic-profile"]?.["registration-date"]?.value;

      if (registrationDateString) {
        const registrationDate = new Date(registrationDateString);
        const twoYearsAgo = new Date();
        twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);

        if (registrationDate < twoYearsAgo) {
          eligibleForFundingSociety = false;
        }
      }

      const companyName =
        singpassData?.entity?.["basic-profile"]?.[
          "entity-name"
        ]?.value?.toLowerCase();
      if (companyName) {
        const hasValidEntityType =
          companyName.includes("pte") || companyName.includes("llp");

        if (!hasValidEntityType) {
          eligibleForFundingSociety = false;
        }
      }

      const individualName = singpassData?.person?.["name"]?.value;
      const shareholderlist =
        singpassData?.entity?.["shareholders"]?.["shareholders-list"];

      if (shareholderlist && Array.isArray(shareholderlist)) {
        let totalShares = 0;
        let individualShares = 0;
        let singaporeanShares = 0;

        shareholderlist.forEach((shareholder) => {
          const allocation = shareholder.allocation?.value || 0;
          totalShares += allocation;

          // Check if the shareholder matches the individualName
          const personName = shareholder["person-name"]?.value;

          if (personName?.toLowerCase() === individualName?.toLowerCase()) {
            individualShares += allocation;
          }

          // Check if the shareholder is Singaporean
          if (shareholder["person-reference"]?.nationality?.code === "SG") {
            singaporeanShares += allocation;
          }
        });

        // Check individual share ownership (more than 50%)
        if (totalShares > 0 && individualShares / totalShares <= 0.5) {
          // console.log("individual share less than 50%")
          eligibleForFundingSociety = false;
        }

        // Check Singaporean share ownership (more than 30%)
        if (totalShares > 0 && singaporeanShares / totalShares <= 0.3) {
          // console.log("singaporean share less than 30%")
          eligibleForFundingSociety = false;
        }
      }

      const individualDOB = singpassData?.person?.["dob"]?.value;
      if (individualDOB) {
        const dob = new Date(individualDOB);
        const today = new Date();
        const age = today.getFullYear() - dob.getFullYear();
        const isBirthdayPassed =
          today.getMonth() > dob.getMonth() ||
          (today.getMonth() === dob.getMonth() &&
            today.getDate() >= dob.getDate());

        const adjustedAge = isBirthdayPassed ? age : age - 1;

        if (adjustedAge >= 50) {
          eligibleForFundingSociety = false;
        }
      }

      const CBSRating = creditReportData?.RiskGrade;
      if (CBSRating === "HH" || CBSRating === "GG") {
        eligibleForFundingSociety = false;
      }

      const overDueBalance = creditReportData?.overdueBalance;
      if (overDueBalance) {
        eligibleForFundingSociety = false;
      }

      const outstandingDefaults = creditReportData?.defaults;
      const bankcruptcyProceedings = creditReportData?.bankcruptcyProceedings;

      if (bankcruptcyProceedings + outstandingDefaults !== 0) {
        eligibleForFundingSociety = false;
      }

      const noaLatest = singpassData?.person?.["noahistory"]?.noas[0];
      if (noaLatest) {
        console.log(noaLatest);
        if (noaLatest.amount.value < 45000) {
          eligibleForFundingSociety = false;
        }
      } else {
        eligibleForFundingSociety = false;
      }
      setEligibleForFundingSocietyLoan(eligibleForFundingSociety);
      // console.log("Eligibility:", eligibleForFundingSociety);
    }
  }, [userDetails]);

  useEffect(() => {}, []);

  useEffect(() => {
    console.log(
      fileList,
      cBSImpLoanSupplementaryMonthlyInstallment,
      CBSImpLoan,
      defaults,
      bankcruptcyProceedings,
      overdueBalance,
      monthlyCommitment,
      chartData,
      enquiryDate,
      chartMaxValue
    );
    if (
      fileList &&
      creditFile !== null &&
      CBSImpLoan !== null &&
      defaults !== null &&
      bankcruptcyProceedings !== null &&
      overdueBalance !== null &&
      monthlyCommitment !== null &&
      chartData !== null &&
      enquiryDate !== null &&
      chartMaxValue &&
      cBSImpLoanSupplementaryMonthlyInstallment &&
      isLessThanOneMonthAgo(enquiryDate)
    ) {
      uploadCreditReportFile();
      setStep(4);
    }
  }, [
    enquiryDate,
    fileList,
    creditFile,
    cBSImpLoanSupplementaryMonthlyInstallment,
    CBSImpLoan,
    defaults,
    bankcruptcyProceedings,
    overdueBalance,
    monthlyCommitment,
    chartData,
    enquiryDate,
    chartMaxValue,
  ]);

  function splitStringToRows(inputString) {
    // Use a regular expression to match exactly two spaces
    const rows = inputString.split(/(?<! ) {2}(?! )/);

    return rows;
  }

  function extractTextBetweeforloandetails(creditText, beforeText, afterText) {
    // Find the starting index of beforeText in the original text
    if (creditText !== null) {
      const startIndex = creditText.indexOf(beforeText);
      if (startIndex === -1) {
        return "Before text not found";
      }

      // Adjust the starting index to exclude the beforeText
      const adjustedStartIndex = startIndex + beforeText.length;

      // Find the ending index of afterText in the original text
      const endIndex = creditText.indexOf(afterText, adjustedStartIndex);
      if (endIndex === -1) {
        return "After text not found";
      }

      // Extract the text between beforeText and afterText
      const extractedText = creditText.substring(adjustedStartIndex, endIndex);

      return extractedText;
    }
  }

  function removeCharactersAroundPage(inputString) {
    // Regular expression to find the word "page" and capture the preceding and following characters
    const regex = /(.{0,19})page(.{0,7})/g; // Adjusted to capture more characters before "page"

    // Replace the matched segments with the word "page" alone
    const result = inputString.replace(regex, (match, p1, p2) => {
      // Determine the number of characters to remove from the beginning and the end
      const charsToRemoveBefore = 19 - 11; // 4 characters before the original 11
      const charsToRemoveAfter = 11 - 4;

      const removedStart =
        p1.length > charsToRemoveBefore
          ? p1.slice(0, -charsToRemoveBefore)
          : "";
      const removedEnd =
        p2.length > charsToRemoveAfter ? p2.slice(charsToRemoveAfter) : "";
      return `${removedStart}page${removedEnd}`;
    });

    return result;
  }

  const uploadCreditReportFile = async () => {
    try {
      const formData1 = new FormData();
      formData1.append("credit_report", creditReportFile);
      const creditReportData = {
        EnquiryDate:
          creditFile && creditFile !== "" && enquiryDate ? enquiryDate : null,
        SecuredCreditLimit:
          creditFile && creditFile !== ""
            ? parseFloat(
                extractTextBetween(
                  "Secured Credit Limit",
                  "Unsecured Credit Limit"
                ).replace(/,/g, "")
              )
            : null,
        UnsecuredCreditLimit:
          creditFile && creditFile !== ""
            ? parseFloat(
                extractTextBetween(
                  "Unsecured Credit Limit",
                  "Exempted Credit Limit"
                ).replace(/,/g, "")
              )
            : null,
        CreditScore:
          creditFile && creditFile !== ""
            ? parseFloat(
                extractTextBetween(
                  "financial institution.  Score",
                  "Risk Grade"
                )
              )
            : null,
        RiskGrade:
          creditFile && creditFile !== ""
            ? extractTextBetween("Risk Grade ", "Risk Grade Description")
            : null,
        ProbabilityofDefault:
          creditFile && creditFile !== ""
            ? parseFloat(
                extractTextBetween(
                  "Probability of Default ",
                  " For more details on the description"
                ).replace(/,/g, "")
              )
            : null,
        SecuredCreditUtilized:
          creditFile && creditFile !== ""
            ? extractTextBetween(
                "Total  ",
                " Aggregated Outstanding Balances for Preceding 5 Months"
              )
                ?.split("  ")
                ?.map((num) => parseFloat(num.replace(/,/g, "")))[0]
            : null,
        UnsecuredCreditUtilized:
          creditFile && creditFile !== ""
            ? extractTextBetween(
                "Total  ",
                " Aggregated Outstanding Balances for Preceding 5 Months"
              )
                ?.split("  ")
                ?.map((num) => parseFloat(num.replace(/,/g, "")))[1]
            : null,
        SecuredCreditUtilizationinpercentage:
          creditFile && creditFile !== ""
            ? (
                (extractTextBetween(
                  "Total  ",
                  " Aggregated Outstanding Balances for Preceding 5 Months"
                )
                  ?.split("  ")
                  ?.map((num) => parseFloat(num.replace(/,/g, "")))[0] /
                  parseFloat(
                    extractTextBetween(
                      "Secured Credit Limit",
                      "Unsecured Credit Limit"
                    )?.replace(/,/g, "")
                  )) *
                100
              )?.toFixed(2)
            : null,
        UnsecuredCreditUtilizationinpercentage:
          creditFile && creditFile !== ""
            ? (
                (extractTextBetween(
                  "Total  ",
                  " Aggregated Outstanding Balances for Preceding 5 Months"
                )
                  ?.split("  ")
                  ?.map((num) => parseFloat(num.replace(/,/g, "")))[1] /
                  parseFloat(
                    extractTextBetween(
                      "Unsecured Credit Limit",
                      "Exempted Credit Limit"
                    )?.replace(/,/g, "")
                  )) *
                100
              )?.toFixed(2)
            : null,
        MonthlyCommitment:
          creditFile && creditFile !== "" && monthlyCommitment
            ? monthlyCommitment.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })
            : null,
        NoteworthyOngoingLoans:
          CBSImpLoan && CBSImpLoan.length !== 0 ? CBSImpLoan : null,
        Last6MonthsGraphChartMaxData:
          chartMaxValue && chartData ? chartMaxValue : null,
        Last6MonthsGraphChartData:
          chartMaxValue && chartData ? chartData : null,
        CBSImpLoanSupplementaryInstallment:
          cBSImpLoanSupplementaryMonthlyInstallment
            ? cBSImpLoanSupplementaryMonthlyInstallment
            : null,
        defaults: defaults,
        bankcruptcyProceedings: bankcruptcyProceedings,
        overdueBalance: overdueBalance,
      };
      formData1.append("credit_report_data", JSON.stringify(creditReportData));

      const updateBorrowerCreditReport = await fetch(
        "https://www.smart-lend.com/api/user/borrowerUpdateCreditReport",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: formData1,
        }
      );

      const json = await updateBorrowerCreditReport.json();
      console.log(json);
      if (updateBorrowerCreditReport.ok) {
        getUserDetails();
      }
    } catch (error) {
      console.error(error);
    }
  };

  function convertDate(dateString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Split the input date string
    const [day, month, year] = dateString.split("/");

    // Convert month number to month name
    const monthName = months[parseInt(month) - 1];

    // Return the formatted date string
    return `${day} ${monthName} ${year}`;
  }
  const isLessThanOneMonthAgo = (dateString) => {
    // Parse the input date string (assuming format is "DD/MM/YYYY")
    const [day, month, year] = dateString.split("/").map(Number);
    const inputDate = new Date(year, month - 1, day); // JavaScript months are 0-based

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in months and years
    const yearDiff = currentDate.getFullYear() - inputDate.getFullYear();
    const monthDiff = currentDate.getMonth() - inputDate.getMonth();

    // Check if the input date is within the last month
    if (yearDiff === 0 && monthDiff <= 1) {
      if (monthDiff === 1) {
        // If the difference is exactly 1 month, check the day to determine if it's within 30/31 days
        const daysDiff = currentDate.getDate() - inputDate.getDate();
        return daysDiff <= 0;
      }
      return true;
    } else if (
      yearDiff === 1 &&
      currentDate.getMonth() === 0 &&
      inputDate.getMonth() === 11
    ) {
      // Special case for dates in December of the previous year and January of the current year
      return currentDate.getDate() <= inputDate.getDate();
    }

    return false;
  };
  function extractTextBetween(beforeText, afterText) {
    if (creditFile !== null) {
      // Replace multiple spaces in beforeText and afterText with \s+ to match any number of spaces
      const beforeTextPattern = beforeText.replace(/\s+/g, "\\s+");
      const afterTextPattern = afterText.replace(/\s+/g, "\\s+");

      // Create a regex to find text between the beforeTextPattern and afterTextPattern
      const regex = new RegExp(`${beforeTextPattern}(.*?)${afterTextPattern}`);

      const match = creditFile.match(regex);
      if (match && match[1]) {
        return match[1].trim(); // Extract and trim any leading/trailing spaces
      } else {
        return "Text not found between specified markers";
      }
    }
  }

  function extractText(file) {
    pdfToText(file)
      .then((text) => {
        console.log(text);
        setCreditFile(text);
      })
      .catch((error) => {
        console.error("Failed to extract text from pdf");
      });
  }
  const UniversaleUploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    // console.log(file);
    try {
      const response1 = await fetch(
        "https://www.smart-lend.com/api/file/uploadUniversalFile",
        {
          method: "POST",
          body: formData,
        }
      );
      if (response1.status === 200) {
        const data = await response1.json();
        const blobUrl = data.url; // Assuming this is the URL you want to store
        // console.log(blobUrl);
        return blobUrl;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSingpassData = async () => {
    let userInformation = null;
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/profile/getUserDetails",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        setUserDetails(json.User);
        if (json.User.credit_report_data) {
          userInformation = json.User;
          setStep(4);
        }
      }
    } catch (error) {
      console.error(error);
    }

    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/singpass/getSingpassData",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        // Handle HTTP error responses (e.g., 404, 500)
        throw new Error(`HTTP Error: ${response.status}`);
      }

      const json = await response.json();
      console.log(json);
      if (json.singpass_data === null) {
        setStep(1);
      } else if (userInformation?.credit_report_data) {
        setStep(4);
      } else {
        setStep(2);
      }

      // Compare dates
    } catch (error) {
      console.error(error);
    }
  };

  const getCbsCode = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/campaign/getcbscode",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        // Handle HTTP error responses (e.g., 404, 500)
        throw new Error(`HTTP Error: ${response.status}`);
      }

      const json = await response.json();
      console.log(json);
      if (json.cbs_campaign_code) {
        setFreeCbsCode(json.cbs_campaign_code);
      }
      // Compare dates
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (step === 2) {
      getCbsCode();
    }
  }, [step]);

  useEffect(() => {
    fetchSingpassData();
  }, []);

  const handleSyncWithSingpass = async () => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/singpass/getAuthoriseApi`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      // Read the response as text since it's not JSON
      const url = await response.text();
      console.log("URL value: ", url);

      if (url.startsWith("http")) {
        // Simple check to ensure it looks like a URL
        window.location.href = url;
      }
    } catch (err) {
      console.error("Error fetching Singpass URL:", err);
    }
  };

  useEffect(() => {
    if (creditFile) {
      const textWithoutPage = removeCharactersAroundPage(
        creditFile.toLowerCase()
      ).replace(/page/g, "");

      const extract = extractTextBetweeforloandetails(
        textWithoutPage,
        "aggregated outstanding balances  month   product type   grantor bank   secured balances  unsecured balances interest bearing  unsecured balances non-interest bearing  exempted unsecured balances  ",
        "total"
      );

      const extract2ForMonthlyCommitment = extractTextBetweeforloandetails(
        textWithoutPage,
        "aggregated monthly instalment",
        "aggregated monthly instalments for preceding 5 months"
      );

      console.log(extract2ForMonthlyCommitment);

      const extract3ForChartingOutstandingBalance =
        extractTextBetweeforloandetails(
          creditFile,
          "Aggregated Outstanding Balances for Preceding 5 Months",
          "Aggregated Monthly Instalment"
        );

      const extract4ForChartingOutstandingBalance =
        extractTextBetweeforloandetails(
          textWithoutPage,
          "aggregated monthly instalment  month   product type   grantor bank   account type**   property***   non-property   unsecured  exempted unsecured  ",
          "aggregated monthly instalments for preceding 5 months"
        );

      const extractEnquiryDate = extractTextBetweeforloandetails(
        textWithoutPage,
        "enquiry date",
        "reference"
      );

      if (extractEnquiryDate !== "Before text not found") {
        setEnquiryDate(extractEnquiryDate)?.trim();
      }
      function parseData(inputString) {
        // Adjusted regex to account for extra numbers before the relevant value
        const regex =
          /([a-zA-Z]+\s\d{4})\s+All\s+All\s+[\d,.]+\s+([\d,.]+\.\d{2})/g;

        const result = [];
        let match;

        // Loop through the matches and create the array of objects
        while ((match = regex.exec(inputString)) !== null) {
          const date = match[1];
          const value = match[2];
          result.push({ date, value });
        }

        return result;
      }

      function getMaxValue(data) {
        return data.reduce((max, current) => {
          // Parse the current value to a float for accurate comparison
          const currentValue = parseFloat(current.value.replace(/,/g, ""));
          return currentValue > max ? currentValue : max;
        }, 0);
      }
      function getNextMonth(data) {
        const monthNames = [
          "january",
          "february",
          "march",
          "april",
          "may",
          "june",
          "july",
          "august",
          "september",
          "october",
          "november",
          "december",
        ];

        // Extract the first date from the data
        const firstDate = data?.[0]?.date;
        if (firstDate !== undefined) {
          const [firstMonth, firstYear] = firstDate.split(" ");
          const monthIndex = monthNames.indexOf(firstMonth.toLowerCase());
          const year = parseInt(firstYear, 10);

          // Calculate the next month and year
          const nextMonthIndex = (monthIndex + 1) % 12;
          const nextYear = monthIndex === 11 ? year + 1 : year;
          const nextMonth = monthNames[nextMonthIndex];

          return `${nextMonth} ${nextYear}`;
        }
      }
      let chartDate = parseData(extract3ForChartingOutstandingBalance);
      // console.log(chartDate);
      if (chartDate && chartDate.length > 0) {
        chartDate.unshift({
          date: getNextMonth(parseData(extract3ForChartingOutstandingBalance)),
          value: extractTextBetween(
            "Total  ",
            " Aggregated Outstanding Balances for Preceding 5 Months"
          )
            ?.split("   ")[1]
            .trim(),
        });

        setChartData(chartDate.reverse());
        setChartMaxValue(getMaxValue(chartDate));
      }
      // console.log(parseData(extract3ForChartingOutstandingBalance));

      // console.log(
      //   splitStringToRows(extract).filter(
      //     (row) =>
      //       row.includes("private residential purchase") ||
      //       row.includes("motor vehicle loan") ||
      //       row.includes("hdb loan")
      //   )
      // );

      // console.log(
      //   splitStringToRows(extract).filter(
      //     (row) =>
      //       row.includes("private residential purchase") ||
      //       row.includes("motor vehicle loan") ||
      //       row.includes("hdb loan")
      //   )
      // );
      const splitExactlyTwoSpaces = (inputString) => {
        // Split the string using the regular expression for exactly two spaces
        const splitArray = inputString.split(/(?<! )  (?! )/);
        return splitArray;
      };
      const combineSingleOrJoint = (splitArray) => {
        const combinedArray = [];

        for (let i = 0; i < splitArray.length; i++) {
          if (
            splitArray[i].startsWith("single") ||
            splitArray[i].startsWith("joint")
          ) {
            if (combinedArray.length > 0) {
              combinedArray[combinedArray.length - 1] += "  " + splitArray[i];
            } else {
              combinedArray.push(splitArray[i]);
            }
          } else {
            combinedArray.push(splitArray[i]);
          }
        }

        return combinedArray;
      };

      const combinePrivateResidentialPurchase = (splitArray) => {
        const combinedArray = [];

        for (let i = 0; i < splitArray.length; i++) {
          if (splitArray[i].includes("private residential purchase")) {
            combinedArray.push(
              splitArray[i] + "  " + (splitArray[i + 1] || "")
            );
            i++; // Skip the next element as it has been combined
          } else {
            combinedArray.push(splitArray[i]);
          }
        }

        return combinedArray;
      };
      // console.log(
      //   combineSingleOrJoint(
      //     combinePrivateResidentialPurchase(
      //       splitExactlyTwoSpaces(extract4ForChartingOutstandingBalance)
      //     )
      //   ).filter(
      //     (row) =>
      //       row.includes("private residential purchase") ||
      //       row.includes("motor vehicle loan") ||
      //       row.includes("hdb loan")
      //   )
      // );

      setCBSImpLoanSupplementaryMonthlyInstallment(
        combineSingleOrJoint(
          combinePrivateResidentialPurchase(
            splitExactlyTwoSpaces(extract4ForChartingOutstandingBalance)
          )
        ).filter(
          (row) =>
            row.includes("private residential purchase") ||
            row.includes("motor vehicle loan") ||
            row.includes("hdb loan")
        )
      );

      setMonthlyCommitment(
        parseFloat(
          extract2ForMonthlyCommitment
            .trim()
            .split("   ")
            [
              extract2ForMonthlyCommitment.trim().split("   ").length - 4
            ]?.replace(/,/g, "")
        ) +
          parseFloat(
            extract2ForMonthlyCommitment
              .trim()
              .split("   ")
              [
                extract2ForMonthlyCommitment.trim().split("   ").length - 3
              ]?.replace(/,/g, "")
          )
      );

      setCBSImpLoan(
        splitStringToRows(extract).filter(
          (row) =>
            row.includes("private residential purchase") ||
            row.includes("motor vehicle loan") ||
            row.includes("hdb loan")
        )
      );
      console.log(
        parseFloat(
          extractTextBetween("Defaults   ", "  Bankruptcy Proceedings   ")
        ),
        parseFloat(
          extractTextBetween(
            "  Bankruptcy Proceedings   ",
            "  Secured Credit Limit "
          )
        )
      );
      setDefaults(
        parseFloat(
          extractTextBetween("Defaults   ", "  Bankruptcy Proceedings   ")
        )
      );
      setBankcruptcyProceedings(
        parseFloat(
          extractTextBetween(
            "  Bankruptcy Proceedings   ",
            "  Secured Credit Limit "
          )
        )
      );
      const targetLine =
        "  account status history product type   grantor bank   account type date opened / date closed   overdue balance last 12 cycles / cash advance / balance transfer full payment  ";

      function formatAndMergeString(input) {
        // Define patterns to split by
        const patterns = [
          "unsecured overdraft",
          "unsecured credit card",
          "other",
          "motor vehicle loan",
          "hdb loan",
          "private residential purchase",
          "unsecured personal loan",
        ];

        // Create a regular expression to split the string at the start of each record
        const regex = new RegExp(`(${patterns.join("|")})`, "g");

        // Split and rejoin the string with line breaks
        const formattedString = input
          .split(regex) // Split using the pattern
          .filter(Boolean) // Remove empty strings
          .map((str) => str.trim()) // Trim unnecessary spaces
          .join("\n"); // Rejoin with line breaks

        // Now, split each line by three or more spaces to create arrays
        const lines = formattedString.split("\n");

        // Merge consecutive elements, but check if the next element exists
        const result = [];
        for (let i = 0; i < lines.length; i += 2) {
          // Check if the next element exists before attempting to split
          if (lines[i + 1]) {
            const record = [lines[i]].concat(
              lines[i + 1].split(/\s{3,}/).map((part) => part.trim())
            );
            result.push(record);
          } else {
            // If there is no next element, just push the current one as is
            result.push([lines[i]]);
          }
        }

        return result;
      }

      function hasOverdueBalance(data) {
        // Iterate over the data (array of arrays)
        return data.some((item) => {
          // Check if the fifth index exists and is a valid number (i.e., a string that can be converted to a number)
          const fifthIndex = item[4];
          return !isNaN(fifthIndex) && parseFloat(fifthIndex) > 0; // If it's a number and greater than 0, consider it as overdue balance
        });
      }

      const extract8ForOverdueBalance = extractTextBetweeforloandetails(
        textWithoutPage,
        "account status history",
        "previous enquiries  date "
      )
        .replace(new RegExp(targetLine, "g"), " ") // Replace all occurrences of targetLine
        .replace(/account status history/g, "") // Replace all occurrences of the specific phrases
        .replace(/product type/g, "")
        .replace(/grantor bank/g, "")
        .replace(/account type/g, "")
        .replace(/date opened \/ date closed/g, "")
        .replace(/overdue balance/g, "")
        .replace(
          /last 12 cycles \/ cash advance \/ balance transfer full payment/g,
          ""
        )
        .trim(); // Trim any leading/trailing spaces

      console.log(
        hasOverdueBalance(formatAndMergeString(extract8ForOverdueBalance))
      );
      setOverdueBalance(
        hasOverdueBalance(formatAndMergeString(extract8ForOverdueBalance))
      );
    }
  }, [creditFile]);

  return (
    <div
      style={{
        height: "calc(100vh - 80px)",
        width: "calc(100% - 40px)",
        marginLeft: "20px",
        background:
          "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
        padding: "20px",
        borderRadius: "10px",
        marginTop: "5px",
        gap: "5px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: step !== 4 ? "" : "flex-start",
        justifyContent: step !== 4 ? "" : "flex-start",
        overflowY: "auto",
      }}
    >
      <Modal
        footer={null}
        open={stepModal !== false}
        // onOk={handleOk}
        onCancel={() => setStepModal(false)}
      >
        {stepModal === 1 ? (
          <div>
            <img
              style={{
                width: "80%",
                height: "80%",
                objectFit: "cover",
                borderRadius: 10,
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                marginTop: 15,
              }}
              src={singpassmobilepage}
            />
            <div style={{ fontWeight: 700, fontSize: 20, marginTop: 20 }}>
              Sync your account with Singpass
            </div>
            <div style={{ fontWeight: 300, fontSize: 14, marginTop: 10 }}>
              Click on Sync Singpass located at the bottom of the page where
              you'll be brought to Singpass official website to allow our
              platform to confirm your identity.
            </div>
          </div>
        ) : stepModal === 2 ? (
          <div>
            <img
              style={{
                width: "80%",
                height: "80%",
                objectFit: "cover",
                borderRadius: 10,
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                marginTop: 15,
              }}
              src={cbsScreenshot}
            />
            <div style={{ fontWeight: 700, fontSize: 20, marginTop: 20 }}>
              Retrieve your Free CBS Report
            </div>
            <div style={{ fontWeight: 300, fontSize: 14, marginTop: 10 }}>
              Copy the promo code above the "get CBS Report" button and click on
              the button where you'll be brought to CBS website where you can
              use the code to redeem your free CBS Report. Proceed to hit Save
              on the final page where you can later upload it back on our
              platform.
            </div>
          </div>
        ) : (
          <div>
            <img
              style={{
                width: "80%",
                height: "80%",
                objectFit: "cover",
                borderRadius: 10,
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                marginTop: 15,
              }}
              src={uploadScreenshot}
            />
            <div style={{ fontWeight: 700, fontSize: 20, marginTop: 20 }}>
              Upload the report
            </div>
            <div style={{ fontWeight: 300, fontSize: 14, marginTop: 10 }}>
              Once you've uploaded the report back on our platform, you'll be
              shown methods where you weigh your options for financing in the
              future.
            </div>
          </div>
        )}
      </Modal>
      {step !== 4 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              width: window.innerWidth > 1200 ? "35vw" : "60vw",
              objectFit: "contain",
              height: window.innerWidth > 1200 ? "15vh" : "15vh",
              marginTop: "40px",
            }}
            src={cbsImage}
          />
          <div
            style={{
              color: "#142C44CC",
              fontSize: "24px",
              lineHeight: "26px",
              fontFamily: "Manrope,sans-serif",
              fontWeight: 800,
              marginTop: "20px",
              marginBottom: "15px",
              width: window.innerWidth > 1200 ? "60%" : "90%",
              textAlign: "center",
            }}
          >
            Get Your Free Credit Bureau Report & Unlock Better Business Loan
            Opportunities
          </div>
          <div
            style={{
              color: "#142C44CC",
              fontSize: "12px",
              lineHeight: "14px",
              fontFamily: "Manrope,sans-serif",
              fontWeight: 500,
              marginBottom: "15px",
              width: window.innerWidth > 1200 ? "70%" : "85%",
              textAlign: "center",
            }}
          >
            For a limited time, SmartLend offers you the chance to retrieve your
            official CBS Credit Report at no cost—complete with an in-depth
            analysis to uncover your best loan options.
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              gap: window.innerWidth > 1200 ? "40px" : "15px",
              color: "#142C44CC",
              fontSize: "18px",
              fontFamily: "Manrope,sans-serif",
              fontWeight: 800,
              textAlign: "center",
              marginTop: "20px",
              flexWrap: "wrap",
            }}
          >
            <div style={{ width: window.innerWidth < 1200 ? "30vw" : "25%" }}>
              <div
                style={{
                  outline: step === 1 ? "4px solid rgb(14, 90, 165)" : "none",
                  padding: "3px",
                  borderRadius: "12px",
                }}
              >
                <img
                  style={{
                    width: window.innerWidth > 1200 ? "12vw" : "30vw",
                    height: window.innerWidth > 1200 ? "12vw" : "30vw",
                    borderRadius: "10px",
                    background: "lightgrey",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onClick={() => setStepModal(1)}
                  src={singpassmobilepage}
                />
              </div>
              <div style={{ marginTop: "15px" }}>Step 1</div>
              <div style={{ fontSize: "13px" }}>Sync with SingPass</div>
              <div style={{ fontSize: "12px", fontWeight: 400 }}>
                Get your free CBS access code instantly by scanning your
                Singpass. (Note: You must have an active business entity
                registered in Singapore.)
              </div>
            </div>
            <div style={{ width: window.innerWidth < 1200 ? "30vw" : "25%" }}>
              <div
                style={{
                  outline: step === 2 ? "4px solid rgb(14, 90, 165)" : "none",
                  padding: "3px",
                  borderRadius: "12px",
                }}
              >
                <img
                  style={{
                    width: window.innerWidth > 1200 ? "12vw" : "30vw",
                    height: window.innerWidth > 1200 ? "12vw" : "30vw",
                    borderRadius: "10px",
                    background: "lightgrey",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onClick={() => setStepModal(2)}
                  src={cbsScreenshot}
                />
              </div>
              <div style={{ marginTop: "15px" }}>Step 2</div>
              <div style={{ fontSize: "13px" }}>Retreive Credit Report</div>
              <div style={{ fontSize: "12px", fontWeight: 400 }}>
                Easily access your official credit report using the [Save]
                button—avoid using the "Print as PDF" option for a seamless
                upload to our platform.
              </div>
            </div>
            <div style={{ width: window.innerWidth < 1200 ? "80%" : "25%" }}>
              <div
                style={{
                  outline: step === 3 ? "4px solid rgb(14, 90, 165)" : "none",
                  padding: "3px",
                  borderRadius: "12px",
                }}
              >
                <img
                  style={{
                    width: window.innerWidth > 1200 ? "12vw" : "30vw",
                    height: window.innerWidth > 1200 ? "12vw" : "30vw",
                    borderRadius: "10px",
                    background: "lightgrey",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onClick={() => setStepModal(3)}
                  src={uploadScreenshot}
                />
              </div>
              <div style={{ marginTop: "15px" }}>Step 3</div>
              <div style={{ fontSize: "13px" }}>Upload Credit Report</div>
              <div style={{ fontSize: "12px", fontWeight: 400 }}>
                Discover your Balance-to-Income (BTI) Ratio—an essential metric
                banks use to evaluate your financial standing.
                <br />
                <br />
                By uploading your credit report, you’ll also receive a
                complimentary assessment of your loan eligibility, empowering
                you with insights to unlock better financing options tailored to
                your business needs.
              </div>
            </div>
          </div>

          {step === 2 && freeCbsCode ? (
            <div
              style={{ marginTop: 20, display: "flex" }}
              className="stepsss2"
            >
              <div
                style={{
                  padding: "10px 45px",
                  borderRadius: "8px 0px 0px 8px",
                  border: "2px solid rgb(14, 90, 165)",
                  color: "rgb(14, 90, 165)",
                  fontWeight: "600",
                }}
              >
                {freeCbsCode}
              </div>
              <div
                onClick={() => {
                  const textToCopy = freeCbsCode; // Replace with the text you want to copy
                  navigator.clipboard
                    .writeText(textToCopy)
                    .then(() => {
                      message.success("Promo code copied to clipboard!");
                    })
                    .catch((err) => {
                      console.error("Failed to copy text: ", err);
                    });
                }}
                style={{
                  background: "rgb(14, 90, 165)",
                  color: "white",
                  borderRadius: "0px 8px 8px 0px",
                  padding: "10px 25px",
                  fontWeight: 700,
                  cursor: "pointer",
                }}
              >
                <ContentPasteIcon style={{ color: "white", fontSize: 24 }} />
              </div>
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "25px",
            }}
          >
            {step === 1 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  className="mui_button"
                  style={{
                    background: "rgb(14, 90, 165)",
                    padding: "8px 24px",
                    borderRadius: "20px",
                  }}
                  onClick={() => {
                    console.log(Cookies.get("sgpasscbs"));
                    Cookies.set("sgpasscbs", 1);
                    handleSyncWithSingpass();
                  }}
                >
                  Sync Singpass
                </div>
                <div style={{ color: "grey", marginTop: "10px" }}>
                  No Business?{" "}
                  <span
                onClick={() => {
                  window.location.href = "https://wa.me/6588288147";
                }}
                    style={{
                      textDecoration: "underline",
                      color: "rgb(14,90,165)",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                  >
                    Contact Us
                  </span>{" "}
                  to get your free CBS access code.
                </div>
              </div>
            ) : step === 2 ? (
              <div
                className="mui_button"
                style={{
                  background: "rgb(14, 90, 165)",
                  padding: "8px 24px",
                  borderRadius: "20px",
                }}
                onClick={() => {
                  setStep(3);
                  window.open(
                    "https://www.creditbureau.com.sg/SmartLend_Financial.html",
                    "_blank"
                  );
                }}
              >
                Get CBS Report
              </div>
            ) : step === 3 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {/* <input type="file" onChange={(e)=>{console.log(e.target.files[0])}}/> */}
                <Upload
                  fileList={fileList}
                  beforeUpload={async (files) => {
                    // Check if multiple files are being uploaded
                    const fileArray = Array.isArray(files) ? files : [files]; // Ensure it's an array
                    const uploadedFiles = await Promise.all(
                      fileArray.map(async (file) => {
                        if (!file.url) {
                          const url = await UniversaleUploadFile(file); // Wait for each file upload

                          // Return the file object with URL and necessary attributes
                          return {
                            uid: file.uid,
                            name: file.name,
                            status: "done",
                            url: url,
                            actualFile: file,
                          };
                        }
                      })
                    );

                    // Update the file list state with the uploaded files
                    setFileList((prevFileList) => [
                      ...prevFileList,
                      ...uploadedFiles,
                    ]);

                    // Process each file individually, if needed
                    for (const file of fileArray) {
                      setCreditReportFile(file);
                      console.log(file);
                      extractText(file);
                    }

                    // Prevent the default upload behavior
                    return false;
                  }}
                  onPreview={async (file) => {
                    if (file.url) {
                      window.open(file.url, "_blank"); // Open the URL in a new tab
                    } else {
                      // Handle cases where URL is not available yet
                      console.log("No URL available for preview.");
                    }
                  }}
                  onRemove={(file) => {
                    setFileList([]); // This assumes you're only handling one file at a time.
                    setCreditReportFile(null);
                    setCreditFile(null);
                  }}
                >
                  <Button
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      marginBottom: "5px",
                      background: "rgb(14, 90, 165)",
                      padding: "12px 24px",
                      borderRadius: "20px",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "7px",
                      border: "none",
                      outline: "none",
                    }}
                    icon={<UploadOutlined />}
                    // onClick={(event) => handleUpload( event)}
                  >
                    Upload Credit Report
                  </Button>
                </Upload>
                <div>
                  {fileList &&
                  fileList.length > 0 &&
                  creditFile &&
                  creditFile !== "" ? (
                    isNaN(
                      parseFloat(
                        extractTextBetween(
                          "Secured Credit Limit",
                          "Unsecured Credit Limit"
                        ).replace(/,/g, "")
                      )
                    ) ? (
                      <div style={{ color: "red" }}>
                        CBS Report not detected, please do not alter CBS report
                        data
                      </div>
                    ) : enquiryDate && isLessThanOneMonthAgo(enquiryDate) ? (
                      <div style={{ fontSize: 12, width: "100%" }}></div>
                    ) : (
                      <div style={{ color: "red" }}>
                        Your current Credit Bureau Report is over a month old.
                        To ensure the accuracy of your application, please
                        upload a fresh Credit Bureau Report.
                      </div>
                    )
                  ) : fileList.length > 0 ? (
                    <div style={{ color: "red" }}>
                      CBS Report not detected, please do not alter CBS report
                      data
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : singpassData ? (
        <div
          style={{
            overflow: "hidden",
            borderRadius: "15px",
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            color: "grey",
          }}
        >
          <div style={{ width: "30%" }}>
            <div
              style={{
                color: "#142C44CC",
                fontSize: "20px",
                fontFamily: "Manrope,sans-serif",
                fontWeight: 800,
                marginBottom: "15px",
                width: "55%",
                textAlign: "left",
              }}
            >
              Financing Options
            </div>
            <div
              style={{
                fontSize: "12px",
                marginTop: "-15px",
                width: "80%",
              }}
            >
              This fields only appear when candidates meets the requirements to
              be eligible to loan with better rates.
            </div>
            <div
              style={{
                width: "90%",
                padding: "12px 20px",
                border: "1px solid rgba(0,0,0,0.1)",
                borderRadius: "8px",
                background: "rgba(256,256,256,0.8)",
                cursor: "pointer",
                marginTop: "20px",
              }}
              className="mui_button"
              onClick={() => {
                history.push("/b-apply-loan");
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <img
                  src={SlLogo}
                  style={{
                    height: "40px",
                    objectFit: "contain",
                    opacity: 0.8,
                  }}
                />
                <div>
                  <div
                    style={{
                      fontWeight: "700",
                      fontSize: "23px",
                      //   color: "#256199",
                      color: "rgb(90,90,90)",
                    }}
                  >
                    SmartLend.
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  color: "rgba(0,0,0,0.7)",
                  fontWeight: 700,
                  fontSize: "18px",
                }}
              >
                Peer-to-peer financing
              </div>
              <div
                style={{
                  fontSize: "12px",
                  marginTop: "2px",
                  display: "flex",
                  gap: "5px",
                }}
              >
                <DoneIcon style={{ color: "#91c7a0" }} />
                <span style={{ fontWeight: 600 }}>$200,000</span> maximum loan
                quantum
              </div>
              <div
                style={{
                  fontSize: "12px",
                  display: "flex",
                  gap: "5px",
                }}
              >
                <DoneIcon style={{ color: "#91c7a0" }} />
                Up-to <span style={{ fontWeight: 600 }}>60 months</span> loan
                tenor
              </div>
              <div
                style={{
                  fontSize: "12px",
                  display: "flex",
                  gap: "5px",
                }}
              >
                <DoneIcon style={{ color: "#91c7a0" }} />
                <span style={{ fontWeight: 600 }}>Negotiable</span> interest and
                processing fees
              </div>
            </div>

            {eligibleForFundingSocietyLoan ? (
              <div
                style={{
                  width: "90%",
                  padding: "12px 20px",
                  border: "1px solid rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  background: "rgba(256,256,256,0.8)",
                  cursor: "pointer",
                  marginTop: "10px",
                }}
                className="mui_button"
                onClick={() => {
                  window.open(
                    "https://fundingsocieties.com/startupfinancing?utm_source=partnership&utm_medium=smarttowkay&utm_campaign=sg_brw_acq_startupfin_partnerships&utm_term=website&utm_content=outreach",
                    "_blank"
                  );
                }}
              >
                <img
                  src={SVGICON}
                  style={{
                    height: "40px",
                    objectFit: "contain",
                    opacity: 0.8,
                  }}
                />
                <div
                  style={{
                    marginTop: "10px",
                    color: "rgba(0,0,0,0.7)",
                    fontWeight: 700,
                    fontSize: "18px",
                  }}
                >
                  Start-up financing
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    marginTop: "2px",
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <DoneIcon style={{ color: "#91c7a0" }} />
                  <span style={{ fontWeight: 600 }}>$10,000</span> loan quantum
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <DoneIcon style={{ color: "#91c7a0" }} />
                  <span style={{ fontWeight: 600 }}>5 months</span> loan tenor
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <DoneIcon style={{ color: "#91c7a0" }} />
                  <span style={{ fontWeight: 600 }}>ZERO</span> interest and
                  fees on timely repayments
                </div>
              </div>
            ) : null}
          </div>
          <div style={{ width: "70%" }}>
            <div
              style={{
                color: "#142C44CC",
                fontSize: "20px",
                fontFamily: "Manrope,sans-serif",
                fontWeight: 800,
                marginBottom: "15px",
                width: "55%",
                textAlign: "left",
              }}
            >
              Your financial Summary
            </div>
            <PDFViewer
              style={{
                width: "100%",
                height: "80vh",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <Document>
                <Page size="A4">
                  {/**Letterhead section */}
                  <View
                    style={{
                      paddingVertical: 15,
                    }}
                  >
                    <View
                      style={{
                        paddingHorizontal: 40,
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: 10,
                        // borderBottom: 1,
                        // borderBottomColor: "grey",
                        paddingBottom: 10,
                      }}
                    >
                      <Image
                        source={logo}
                        style={{ width: 80, objectFit: "contain" }}
                      />
                      <View>
                        <Text
                          style={{
                            fontSize: 18,
                            fontFamily: "Poppins",
                            fontWeight: 700,
                          }}
                        >
                          SmartLend Financial Pte. Ltd.
                        </Text>
                        <View
                          style={{
                            flexDirection: "row",
                            gap: 4,
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: -3,
                          }}
                        >
                          <Text style={{ fontSize: 7 }}>UEN: 202327568G</Text>
                          <Text style={{ fontSize: 7 }}>Tel: +65 88288147</Text>
                          <Text style={{ fontSize: 7 }}>
                            Email: support@smart-lend.com
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        backgroundColor: "lightgrey",
                        height: 2,
                        width: "100%",
                        marginTop: 20,
                      }}
                    />
                    <View
                      style={{
                        backgroundColor: "#2881c4",
                        height: 4,
                        width: "76%",
                        marginTop: -3,
                        marginLeft: "12%",
                        marginBottom: 15,
                      }}
                    />
                    <View style={{ marginTop: 15, paddingHorizontal: 40 }}>
                      <View
                        style={{
                          backgroundColor: "#c7dcec",
                          alignItems: "center",
                          padding: 6,
                          borderTopLeftRadius: 7,
                          borderTopRightRadius: 7,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 10,
                            fontFamily: "Poppins",
                            fontWeight: 700,
                          }}
                        >
                          Company Profile
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          padding: 8,
                          borderColor: "#c7dcec",
                          borderLeftWidth: 2,
                          borderRightWidth: 2,
                          borderBottomWidth: 2,
                        }}
                      >
                        <View style={{ width: "50%" }}>
                          <View
                            style={{
                              flexDirection: "row",
                              gap: 10,
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 7,
                                fontFamily: "Poppins",
                                fontWeight: 700,
                              }}
                            >
                              Entity Name:
                            </Text>
                            <Text style={{ fontSize: 7, width: "65%" }}>
                              {
                                singpassData?.entity["basic-profile"][
                                  "entity-name"
                                ]?.value
                              }
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              gap: 10,
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 7,
                                fontFamily: "Poppins",
                                fontWeight: 700,
                              }}
                            >
                              UEN:
                            </Text>
                            <Text style={{ fontSize: 7, width: "65%" }}>
                              {
                                singpassData?.entity["basic-profile"]?.uen
                                  ?.value
                              }
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              gap: 10,
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 7,
                                fontFamily: "Poppins",
                                fontWeight: 700,
                              }}
                            >
                              Registration Date:
                            </Text>
                            <Text style={{ fontSize: 7, width: "65%" }}>
                              {
                                singpassData?.entity?.["basic-profile"]?.[
                                  "registration-date"
                                ]?.value
                              }
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              gap: 10,
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 7,
                                fontFamily: "Poppins",
                                fontWeight: 700,
                              }}
                            >
                              Primary Activity:
                            </Text>
                            <Text style={{ fontSize: 7, width: "65%" }}>
                              {
                                singpassData?.entity["basic-profile"][
                                  "primary-activity"
                                ]?.desc
                              }
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              gap: 10,
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 7,
                                fontFamily: "Poppins",
                                fontWeight: 700,
                              }}
                            >
                              Ownership:
                            </Text>
                            <Text style={{ fontSize: 7, width: "65%" }}>
                              {
                                singpassData?.entity?.["basic-profile"]?.[
                                  "ownership"
                                ]?.desc
                              }
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              gap: 10,
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 7,
                                fontFamily: "Poppins",
                                fontWeight: 700,
                              }}
                            >
                              Entity Status:
                            </Text>
                            <Text style={{ fontSize: 7, width: "65%" }}>
                              {
                                singpassData?.entity?.["basic-profile"]?.[
                                  "entity-status"
                                ]?.value
                              }
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              gap: 10,
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 7,
                                fontFamily: "Poppins",
                                fontWeight: 700,
                              }}
                            >
                              Business Constitution:
                            </Text>
                            <Text style={{ fontSize: 7, width: "65%" }}>
                              {
                                singpassData?.entity?.["basic-profile"]?.[
                                  "company-type"
                                ]?.desc
                              }
                            </Text>
                          </View>
                          <View style={{ flexDirection: "row", gap: 10 }}>
                            <Text
                              style={{
                                fontSize: 7,
                                fontFamily: "Poppins",
                                fontWeight: 700,
                              }}
                            >
                              Business Verification By Smartlend:
                            </Text>
                            <Text style={{ fontSize: 7 }}>
                              {badgeVerified ? "Verfied" : "Un-verified"}
                            </Text>
                          </View>
                        </View>
                        <View style={{ width: "50%" }}>
                          <View
                            style={{
                              flexDirection: "row",
                              gap: 10,
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 7,
                                fontFamily: "Poppins",
                                fontWeight: 700,
                              }}
                            >
                              ACRA Address:
                            </Text>
                            <Text style={{ fontSize: 7, width: "60%" }}>
                              {singpassData?.entity?.addresses[
                                "addresses-list"
                              ][0]
                                ? (singpassData?.entity?.addresses?.[
                                    "addresses-list"
                                  ]?.[0]?.unit?.value !== undefined
                                    ? `Unit ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.unit?.value}, `
                                    : "") +
                                  (singpassData?.entity?.addresses?.[
                                    "addresses-list"
                                  ]?.[0]?.floor?.value !== undefined
                                    ? `Floor ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.floor?.value}, `
                                    : "") +
                                  (singpassData?.entity?.addresses?.[
                                    "addresses-list"
                                  ]?.[0]?.block?.value !== undefined
                                    ? `Block ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.block?.value}, `
                                    : "") +
                                  (singpassData?.entity?.addresses?.[
                                    "addresses-list"
                                  ]?.[0]?.street?.value !== undefined
                                    ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.street?.value}, `
                                    : "") +
                                  (singpassData?.entity?.addresses?.[
                                    "addresses-list"
                                  ]?.[0]?.postal?.value !== undefined
                                    ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.postal?.value}, `
                                    : "") +
                                  (singpassData?.entity?.addresses?.[
                                    "addresses-list"
                                  ]?.[0]?.country?.desc !== undefined
                                    ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.country?.desc}.`
                                    : "")
                                : ""}
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              gap: 10,
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 7,
                                fontFamily: "Poppins",
                                fontWeight: 700,
                              }}
                            >
                              Capital Type:
                            </Text>
                            <Text style={{ fontSize: 7, width: "60%" }}>
                              {
                                singpassData?.entity?.["capitals"]?.[
                                  "capitals-list"
                                ]?.[0]?.["capital-type"]?.desc
                              }
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              gap: 10,
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 7,
                                fontFamily: "Poppins",
                                fontWeight: 700,
                              }}
                            >
                              Paid Up Capital:
                            </Text>
                            <Text style={{ fontSize: 7, width: "60%" }}>
                              {singpassData?.entity?.["capitals"]?.[
                                "capitals-list"
                              ]?.[0]?.[
                                "paid-up-capital-amount"
                              ]?.value?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              gap: 10,
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 7,
                                fontFamily: "Poppins",
                                fontWeight: 700,
                              }}
                            >
                              Share Allocated Amount:
                            </Text>
                            <Text style={{ fontSize: 7, width: "60%" }}>
                              {singpassData?.entity?.["capitals"]?.[
                                "capitals-list"
                              ]?.[0]?.[
                                "share-allotted-amount"
                              ]?.value?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              gap: 10,
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 7,
                                fontFamily: "Poppins",
                                fontWeight: 700,
                              }}
                            >
                              Issued Capital Amount:
                            </Text>
                            <Text style={{ fontSize: 7, width: "60%" }}>
                              {singpassData?.entity?.["capitals"]?.[
                                "capitals-list"
                              ]?.[0]?.[
                                "issued-capital-amount"
                              ]?.value?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              gap: 10,
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 7,
                                fontFamily: "Poppins",
                                fontWeight: 700,
                              }}
                            >
                              Keyman Mobile:
                            </Text>
                            <Text style={{ fontSize: 7, width: "60%" }}>
                              {getShareholderAddress(keyman)?.[0]?.person
                                ?.mobileno?.nbr?.value !== undefined
                                ? getShareholderAddress(keyman)?.[0]?.person
                                    ?.mobileno?.prefix?.value +
                                  getShareholderAddress(keyman)?.[0]?.person
                                    ?.mobileno?.areacode?.value +
                                  "-" +
                                  getShareholderAddress(keyman)?.[0]?.person
                                    ?.mobileno?.nbr?.value
                                : ""}
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              gap: 10,
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 7,
                                fontFamily: "Poppins",
                                fontWeight: 700,
                              }}
                            >
                              Keyman Email:
                            </Text>
                            <Text style={{ fontSize: 7, width: "60%" }}>
                              {getShareholderAddress(keyman)?.[0]?.person?.email
                                ?.value !== undefined
                                ? getShareholderAddress(keyman)?.[0]?.person
                                    ?.email?.value
                                : ""}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={{ marginTop: 15, paddingHorizontal: 40 }}>
                      <View
                        style={{
                          backgroundColor: "#c7dcec",
                          alignItems: "center",
                          padding: 6,
                          borderTopLeftRadius: 7,
                          borderTopRightRadius: 7,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 10,
                            fontFamily: "Poppins",
                            fontWeight: 700,
                          }}
                        >
                          Financial Highlight
                        </Text>
                      </View>
                      <View
                        style={{
                          borderColor: "#c7dcec",
                          borderLeftWidth: 2,
                          borderRightWidth: 2,
                          borderBottomWidth: 2,
                          width: "100%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            width: "100%",
                            backgroundColor: "#c7dcec",
                            opacity: 0.7,
                            padding: 4,
                          }}
                        >
                          <View
                            style={{
                              width: "19%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Revenue</Text>
                          </View>
                          <View
                            style={{
                              width: "19%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Profit pre-tax</Text>
                          </View>
                          <View
                            style={{
                              width: "19%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Profit post-tax</Text>
                          </View>
                          <View
                            style={{
                              width: "19%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Start date</Text>
                          </View>
                          <View
                            style={{
                              width: "19%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>End date</Text>
                          </View>
                        </View>
                        {singpassData?.entity?.financials?.highlights?.map(
                          (data, index) => (
                            <View
                              key={index}
                              style={{
                                flexDirection: "row",
                                width: "100%",
                                backgroundColor:
                                  index % 2
                                    ? "rgba(199, 220, 236, 0.2)"
                                    : "transparent",
                                padding: 4,
                              }}
                            >
                              <View style={{ width: "19%", fontSize: 7 }}>
                                <Text>
                                  {data?.[
                                    "company-revenue"
                                  ]?.value?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "SGD",
                                  })}
                                </Text>
                              </View>
                              <View style={{ width: "19%", fontSize: 7 }}>
                                <Text>
                                  {data?.[
                                    "company-profit-loss-before-tax"
                                  ]?.value?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "SGD",
                                  })}
                                </Text>
                              </View>
                              <View style={{ width: "19%", fontSize: 7 }}>
                                <Text>
                                  {data?.[
                                    "company-profit-loss-after-tax"
                                  ]?.value?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "SGD",
                                  })}
                                </Text>
                              </View>
                              <View style={{ width: "19%", fontSize: 7 }}>
                                <Text>
                                  {data?.["current-period-start-date"]?.value}
                                </Text>
                              </View>
                              <View style={{ width: "19%", fontSize: 7 }}>
                                <Text>
                                  {data?.["current-period-end-date"]?.value}
                                </Text>
                              </View>
                            </View>
                          )
                        )}
                      </View>
                    </View>
                    <View style={{ marginTop: 15, paddingHorizontal: 40 }}>
                      <View
                        style={{
                          backgroundColor: "#c7dcec",
                          alignItems: "center",
                          padding: 6,
                          borderTopLeftRadius: 7,
                          borderTopRightRadius: 7,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 10,
                            fontFamily: "Poppins",
                            fontWeight: 700,
                          }}
                        >
                          Grants
                        </Text>
                      </View>
                      <View
                        style={{
                          borderColor: "#c7dcec",
                          borderLeftWidth: 2,
                          borderRightWidth: 2,
                          borderBottomWidth: 2,
                          width: "100%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            width: "100%",
                            backgroundColor: "#c7dcec",
                            opacity: 0.7,
                            padding: 4,
                            gap: 10,
                          }}
                        >
                          <View
                            style={{
                              width: "19%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Category</Text>
                          </View>
                          <View
                            style={{
                              width: "19%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Functional Area</Text>
                          </View>
                          <View
                            style={{
                              width: "19%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Grant Type</Text>
                          </View>
                          <View
                            style={{
                              width: "19%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Grant Status</Text>
                          </View>
                          <View
                            style={{
                              width: "19%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Approved Amount</Text>
                          </View>
                        </View>
                        {singpassData?.entity?.grants?.["grants-list"]?.map(
                          (data, index) => (
                            <View
                              key={index}
                              style={{
                                flexDirection: "row",
                                width: "100%",
                                backgroundColor:
                                  index % 2
                                    ? "rgba(199, 220, 236, 0.2)"
                                    : "transparent",
                                padding: 4,
                                gap: 10,
                              }}
                            >
                              <View style={{ width: "19%", fontSize: 7 }}>
                                <Text>
                                  {data?.["development-category"]?.desc}
                                </Text>
                              </View>
                              <View style={{ width: "19%", fontSize: 7 }}>
                                <Text>{data?.["functional-area"]?.desc}</Text>
                              </View>
                              <View style={{ width: "19%", fontSize: 7 }}>
                                <Text>{data?.["grant-type"]?.desc}</Text>
                              </View>
                              <View style={{ width: "19%", fontSize: 7 }}>
                                <Text>{data?.["grant-status"]?.desc}</Text>
                              </View>
                              <View style={{ width: "19%", fontSize: 7 }}>
                                <Text>
                                  {data?.[
                                    "approved-amount"
                                  ]?.value?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "SGD",
                                  })}
                                </Text>
                              </View>
                            </View>
                          )
                        )}
                      </View>
                    </View>
                    <View style={{ marginTop: 15, paddingHorizontal: 40 }}>
                      <View
                        style={{
                          backgroundColor: "#c7dcec",
                          alignItems: "center",
                          padding: 6,
                          borderTopLeftRadius: 7,
                          borderTopRightRadius: 7,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 10,
                            fontFamily: "Poppins",
                            fontWeight: 700,
                          }}
                        >
                          Officer(S) / Owners
                        </Text>
                      </View>
                      <View
                        style={{
                          borderColor: "#c7dcec",
                          borderLeftWidth: 2,
                          borderRightWidth: 2,
                          borderBottomWidth: 2,
                          width: "100%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            width: "100%",
                            backgroundColor: "#c7dcec",
                            opacity: 0.7,
                            padding: 4,
                            gap: 10,
                          }}
                        >
                          <View
                            style={{
                              width: "25%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Officer Name</Text>
                          </View>
                          <View
                            style={{
                              width: "13%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Identity No.</Text>
                          </View>
                          <View
                            style={{
                              width: "13%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Position</Text>
                          </View>
                          <View
                            style={{
                              width: "13%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Appointment Date</Text>
                          </View>
                          <View
                            style={{
                              width: "19%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Nationality</Text>
                          </View>
                        </View>
                        {singpassData?.entity?.appointments?.[
                          "appointments-list"
                        ]?.map((data, index) => (
                          <View
                            key={index}
                            style={{
                              flexDirection: "row",
                              width: "100%",
                              backgroundColor:
                                index % 2
                                  ? "rgba(199, 220, 236, 0.2)"
                                  : "transparent",
                              padding: 4,
                              gap: 10,
                            }}
                          >
                            <View style={{ width: "25%", fontSize: 7 }}>
                              <Text>
                                {
                                  data?.["person-reference"]?.["person-name"]
                                    ?.value
                                }
                              </Text>
                            </View>
                            <View style={{ width: "13%", fontSize: 7 }}>
                              <Text>
                                {data?.["person-reference"]?.["idno"]?.value}
                              </Text>
                            </View>
                            <View style={{ width: "13%", fontSize: 7 }}>
                              <Text>{data?.["position"]?.desc}</Text>
                            </View>
                            <View style={{ width: "13%", fontSize: 7 }}>
                              <Text>{data?.["appointment-date"]?.value}</Text>
                            </View>
                            <View style={{ width: "19%", fontSize: 7 }}>
                              <Text>
                                {
                                  data?.["person-reference"]?.["nationality"]
                                    ?.desc
                                }
                              </Text>
                            </View>
                          </View>
                        ))}
                      </View>
                    </View>
                    <View style={{ marginTop: 15, paddingHorizontal: 40 }}>
                      <View
                        style={{
                          backgroundColor: "#c7dcec",
                          alignItems: "center",
                          padding: 6,
                          borderTopLeftRadius: 7,
                          borderTopRightRadius: 7,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 10,
                            fontFamily: "Poppins",
                            fontWeight: 700,
                          }}
                        >
                          Shareholders
                        </Text>
                      </View>
                      <View
                        style={{
                          borderColor: "#c7dcec",
                          borderLeftWidth: 2,
                          borderRightWidth: 2,
                          borderBottomWidth: 2,
                          width: "100%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            width: "100%",
                            backgroundColor: "#c7dcec",
                            opacity: 0.7,
                            padding: 4,
                            gap: 10,
                          }}
                        >
                          <View
                            style={{
                              width: "25%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Shareholder Name / Address</Text>
                          </View>
                          <View
                            style={{
                              width: "13%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Identity No.</Text>
                          </View>
                          <View
                            style={{
                              width: "18%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Share Type</Text>
                          </View>
                          <View
                            style={{
                              width: "13%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>No of Share</Text>
                          </View>
                          <View
                            style={{
                              width: "19%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Nationality</Text>
                          </View>
                          <View
                            style={{
                              width: "15%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Date of birth</Text>
                          </View>
                          <View
                            style={{
                              width: "19%",
                              fontSize: 7,
                              fontFamily: "Poppins",
                            }}
                          >
                            <Text>Corppass Contact</Text>
                          </View>
                        </View>
                        {singpassData?.entity?.shareholders?.[
                          "shareholders-list"
                        ]?.map((data, index) => (
                          <View
                            key={index}
                            style={{
                              flexDirection: "row",
                              width: "100%",
                              backgroundColor:
                                index % 2
                                  ? "rgba(199, 220, 236, 0.2)"
                                  : "transparent",
                              padding: 4,
                              gap: 10,
                            }}
                          >
                            <View
                              style={{
                                width: "25%",
                                fontSize: 7,
                              }}
                            >
                              <Text>
                                {data?.["person-reference"]?.["person-name"]
                                  ?.value
                                  ? data?.["person-reference"]?.["person-name"]
                                      ?.value +
                                    " (" +
                                    getShareholderAddress(
                                      data?.["person-reference"]?.[
                                        "person-name"
                                      ]?.value
                                    )?.[0]?.person?.sex?.code +
                                    ")"
                                  : data?.["entity-reference"]?.["entity-name"]
                                      ?.value + " (Corporate)"}
                              </Text>
                              <Text>
                                {getShareholderAddress(
                                  data?.["person-reference"]?.["person-name"]
                                    ?.value
                                )?.[0]?.person?.regadd
                                  ? (getShareholderAddress(
                                      data?.["person-reference"]?.[
                                        "person-name"
                                      ]?.value
                                    )?.[0]?.person?.regadd?.unit?.value !== null
                                      ? `Unit ${
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.regadd?.unit?.value
                                        }, `
                                      : "") +
                                    (getShareholderAddress(
                                      data?.["person-reference"]?.[
                                        "person-name"
                                      ]?.value
                                    )?.[0]?.person?.regadd?.floor?.value !==
                                    null
                                      ? `Floor ${
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.regadd?.floor?.value
                                        }, `
                                      : "") +
                                    (getShareholderAddress(
                                      data?.["person-reference"]?.[
                                        "person-name"
                                      ]?.value
                                    )?.[0]?.person?.regadd?.block?.value !==
                                    null
                                      ? `Block ${
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.regadd?.block?.value
                                        }, `
                                      : "") +
                                    (getShareholderAddress(
                                      data?.["person-reference"]?.[
                                        "person-name"
                                      ]?.value
                                    )?.[0]?.person?.regadd?.street?.value !==
                                    null
                                      ? `${
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.regadd?.street?.value
                                        }, `
                                      : "") +
                                    (getShareholderAddress(
                                      data?.["person-reference"]?.[
                                        "person-name"
                                      ]?.value
                                    )?.[0]?.person?.regadd?.postal?.value !==
                                    null
                                      ? `${
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.regadd?.postal?.value
                                        }, `
                                      : "") +
                                    (getShareholderAddress(
                                      data?.["person-reference"]?.[
                                        "person-name"
                                      ]?.value
                                    )?.[0]?.person?.regadd?.country?.desc !==
                                    null
                                      ? `${
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.regadd?.country?.desc
                                        }.`
                                      : "")
                                  : ""}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "13%",
                                fontSize: 7,
                              }}
                            >
                              <Text>
                                {data?.["person-reference"]?.["idno"]?.value
                                  ? data?.["person-reference"]?.["idno"]?.value
                                  : data?.["entity-reference"]?.["uen"]?.value +
                                    " (UEN)"}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "18%",
                                fontSize: 7,
                              }}
                            >
                              <Text>{data?.["share-type"]?.desc}</Text>
                            </View>
                            <View
                              style={{
                                width: "13%",
                                fontSize: 7,
                              }}
                            >
                              <Text>{data?.["allocation"]?.value}</Text>
                            </View>
                            <View
                              style={{
                                width: "19%",
                                fontSize: 7,
                              }}
                            >
                              <Text>
                                {
                                  data?.["person-reference"]?.["nationality"]
                                    ?.desc
                                }
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "15%",
                                fontSize: 7,
                              }}
                            >
                              <Text>
                                {
                                  getShareholderAddress(
                                    data?.["person-reference"]?.["person-name"]
                                      ?.value
                                  )?.[0]?.person?.dob?.value
                                }
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "19%",
                                fontSize: 7,
                              }}
                            >
                              <Text>
                                {
                                  getShareholderAddress(
                                    data?.["person-reference"]?.["person-name"]
                                      ?.value
                                  )?.[0]?.person?.email?.value
                                }
                              </Text>
                              <Text>
                                {
                                  getShareholderAddress(
                                    data?.["person-reference"]?.["person-name"]
                                      ?.value
                                  )?.[0]?.person?.mobileno?.nbr?.value
                                }
                              </Text>
                            </View>
                          </View>
                        ))}
                      </View>
                    </View>
                  </View>
                  <View></View>
                </Page>
                {combinedSingpassData
                  ? combinedSingpassData.map((data, index) => (
                      <Page key={index} size="A4">
                        {/**Letterhead section */}
                        <View
                          style={{
                            paddingVertical: 15,
                          }}
                        >
                          <View
                            style={{
                              marginTop: 15,
                              paddingHorizontal: 40,
                            }}
                          >
                            <View
                              style={{
                                backgroundColor: "#c7dcec",
                                alignItems: "center",
                                padding: 6,
                                borderTopLeftRadius: 7,
                                borderTopRightRadius: 7,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 10,
                                  fontFamily: "Poppins",
                                  fontWeight: 700,
                                }}
                              >
                                {data?.person?.name?.value +
                                  " | Personal Information"}
                              </Text>
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                padding: 8,
                                borderColor: "#c7dcec",
                                borderLeftWidth: 2,
                                borderRightWidth: 2,
                                borderBottomWidth: 2,
                              }}
                            >
                              <View style={{ width: "50%" }}>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Ownership of Private Residential Property:
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 7,
                                    }}
                                  >
                                    {data?.person?.ownerprivate?.value
                                      ? "Yes"
                                      : "No"}
                                  </Text>
                                </View>
                                {data?.person?.hdbownership
                                  ? data?.person?.hdbownership?.length > 0
                                    ? data?.person?.hdbownership?.map(
                                        (hdb, index3) => (
                                          <View key={index3}>
                                            <View
                                              style={{
                                                flexDirection: "row",
                                                gap: 10,
                                                marginTop: 10,
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 700,
                                                }}
                                              >
                                                HDB {index3 + 1}
                                              </Text>
                                            </View>

                                            <View
                                              style={{
                                                flexDirection: "row",
                                                gap: 10,
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 700,
                                                }}
                                              >
                                                HDB Ownership:
                                              </Text>
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  width: "65%",
                                                }}
                                              >
                                                {hdb?.address
                                                  ? (hdb?.address?.unit
                                                      ?.value !== null
                                                      ? `Unit ${hdb?.address?.unit?.value}, `
                                                      : "") +
                                                    (hdb?.address?.floor
                                                      ?.value !== null
                                                      ? `Floor ${hdb?.address?.floor?.value}, `
                                                      : "") +
                                                    (hdb?.address?.block
                                                      ?.value !== null
                                                      ? `Block ${hdb?.address?.block?.value}, `
                                                      : "") +
                                                    (hdb?.address?.street
                                                      ?.value !== null
                                                      ? `${hdb?.address?.street?.value}, `
                                                      : "") +
                                                    (hdb?.address?.postal
                                                      ?.value !== null
                                                      ? `${hdb?.address?.postal?.value}, `
                                                      : "") +
                                                    (hdb?.address?.country
                                                      ?.desc !== null
                                                      ? `${hdb?.address?.country?.desc}.`
                                                      : "")
                                                  : ""}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                flexDirection: "row",
                                                gap: 10,
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 700,
                                                }}
                                              >
                                                Outstanding HDB Loan Balance:
                                              </Text>
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  width: "30%",
                                                }}
                                              >
                                                {hdb?.outstandingloanbalance?.value?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                flexDirection: "row",
                                                gap: 10,
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 700,
                                                }}
                                              >
                                                Type of HDB:
                                              </Text>
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  width: "65%",
                                                }}
                                              >
                                                {hdb?.hdbtype?.desc}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                flexDirection: "row",
                                                gap: 10,
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 700,
                                                }}
                                              >
                                                Monthly Loan Installment:
                                              </Text>
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  width: "65%",
                                                }}
                                              >
                                                {hdb?.monthlyloaninstalment?.value?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                          </View>
                                        )
                                      )
                                    : "No"
                                  : "No"}

                                <View>
                                  {data?.person?.cpfhousingwithdrawal
                                    ?.withdrawaldetails?.length > 0
                                    ? data?.person?.cpfhousingwithdrawal?.withdrawaldetails.map(
                                        (cpf, index) => (
                                          <View
                                            style={{ marginTop: 10 }}
                                            key={index}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              CPF Usage{" "}
                                              {(cpf.address.unit?.value !==
                                              undefined
                                                ? `Unit ${cpf.address.unit?.value}, `
                                                : "") +
                                                (cpf.address.floor?.value !==
                                                undefined
                                                  ? `Floor ${cpf.address.floor?.value}, `
                                                  : "") +
                                                (cpf.address.block?.value !==
                                                undefined
                                                  ? `Block ${cpf.address.block?.value}, `
                                                  : "") +
                                                (cpf.address.street?.value !==
                                                undefined
                                                  ? `${cpf.address.street?.value}, `
                                                  : "") +
                                                (cpf.address.postal?.value !==
                                                undefined
                                                  ? `${cpf.address.postal?.value}, `
                                                  : "") +
                                                (cpf.address.country?.desc !==
                                                undefined
                                                  ? `${cpf.address.country?.desc}.`
                                                  : "")}
                                              :
                                            </Text>
                                            <View
                                              style={{
                                                flexDirection: "row",
                                                gap: 10,
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 700,
                                                }}
                                              >
                                                Principal Withdrawal Amount:
                                              </Text>
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  width: "30%",
                                                }}
                                              >
                                                {cpf?.principalwithdrawalamt?.value?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                flexDirection: "row",
                                                gap: 10,
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 700,
                                                }}
                                              >
                                                Accrued Interest Amount:
                                              </Text>
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  width: "30%",
                                                }}
                                              >
                                                {cpf?.accruedinterestamt?.value?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                flexDirection: "row",
                                                gap: 10,
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 700,
                                                }}
                                              >
                                                Monthly Installment (CPF):
                                              </Text>
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  width: "30%",
                                                }}
                                              >
                                                {cpf?.monthlyinstalmentamt?.value?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                flexDirection: "row",
                                                gap: 10,
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 700,
                                                }}
                                              >
                                                Total Amount of CPF Allowed For
                                                Property:
                                              </Text>
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  width: "30%",
                                                }}
                                              >
                                                {cpf?.totalamountofcpfallowedforproperty?.value?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                          </View>
                                        )
                                      )
                                    : "None"}
                                </View>
                              </View>
                              <View style={{ width: "50%" }}>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Contact:
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      width: "65%",
                                    }}
                                  >
                                    {data?.person?.mobileno?.nbr?.value}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Email:
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      width: "65%",
                                    }}
                                  >
                                    {data?.person?.email?.value}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Marital Status:
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      width: "65%",
                                    }}
                                  >
                                    {data?.person?.marital?.desc}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Vehicles:
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      width: "65%",
                                    }}
                                  >
                                    {data?.person?.vehicles?.length > 0
                                      ? data?.person?.vehicles?.map(
                                          (veh, index4) => (
                                            <Text
                                              key={index4}
                                              style={{
                                                fontSize: 7,
                                                width: "65%",
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  width: "65%",
                                                }}
                                              >
                                                {veh?.make?.value +
                                                  ": " +
                                                  veh?.model?.value}
                                              </Text>
                                            </Text>
                                          )
                                        )
                                      : "None"}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>
                          <View
                            style={{
                              marginTop: 15,
                              paddingHorizontal: 40,
                            }}
                          >
                            <View
                              style={{
                                backgroundColor: "#c7dcec",
                                alignItems: "center",
                                padding: 6,
                                borderTopLeftRadius: 7,
                                borderTopRightRadius: 7,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 10,
                                  fontFamily: "Poppins",
                                  fontWeight: 700,
                                }}
                              >
                                {data?.person?.name?.value +
                                  " | Notice of Assessments"}
                              </Text>
                            </View>
                            <View
                              style={{
                                borderColor: "#c7dcec",
                                borderLeftWidth: 2,
                                borderRightWidth: 2,
                                borderBottomWidth: 2,
                                width: "100%",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  width: "100%",
                                  backgroundColor: "#c7dcec",
                                  opacity: 0.7,
                                  padding: 4,
                                  gap: 10,
                                }}
                              >
                                <View
                                  style={{
                                    width: "14%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Year of Assesment</Text>
                                </View>
                                <View
                                  style={{
                                    width: "14%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Amount</Text>
                                </View>
                                <View
                                  style={{
                                    width: "14%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Employment</Text>
                                </View>
                                <View
                                  style={{
                                    width: "14%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Interest</Text>
                                </View>
                                <View
                                  style={{
                                    width: "14%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Rent</Text>
                                </View>
                                <View
                                  style={{
                                    width: "14%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Trade</Text>
                                </View>
                                <View
                                  style={{
                                    width: "14%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Tax Clearance</Text>
                                </View>
                              </View>
                              {data?.person?.noahistory?.noas?.map(
                                (data2, index2) => (
                                  <View
                                    key={index2}
                                    style={{
                                      flexDirection: "row",
                                      width: "100%",
                                      backgroundColor:
                                        index2 % 2
                                          ? "rgba(199, 220, 236, 0.2)"
                                          : "transparent",
                                      padding: 4,
                                      gap: 10,
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: "14%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {data2?.["yearofassessment"]?.value}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "14%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {data2?.[
                                          "amount"
                                        ]?.value?.toLocaleString("en-us", {
                                          style: "currency",
                                          currency: "SGD",
                                        })}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "14%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {data2?.[
                                          "employment"
                                        ]?.value?.toLocaleString("en-us", {
                                          style: "currency",
                                          currency: "SGD",
                                        })}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "14%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {data2?.[
                                          "interest"
                                        ]?.value?.toLocaleString("en-us", {
                                          style: "currency",
                                          currency: "SGD",
                                        })}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "14%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {data2?.["rent"]?.value?.toLocaleString(
                                          "en-us",
                                          {
                                            style: "currency",
                                            currency: "SGD",
                                          }
                                        )}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "14%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {data2?.[
                                          "trade"
                                        ]?.value?.toLocaleString("en-us", {
                                          style: "currency",
                                          currency: "SGD",
                                        })}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "14%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {data2?.["taxclearance"]?.value}
                                      </Text>
                                    </View>
                                  </View>
                                )
                              )}
                            </View>
                          </View>
                          {allCBSData?.filter(
                            (dat2) => dat2?.name === data?.person?.name?.value
                          )?.[0] ? (
                            <View
                              style={{
                                marginTop: 15,
                                paddingHorizontal: 40,
                              }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  {data?.person?.name?.value +
                                    " | Credit Bureau | Personal Commitement"}
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  padding: 8,
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                }}
                              >
                                <View style={{ width: "50%" }}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Secured Credit Limit:
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 7,
                                      }}
                                    >
                                      {allCBSData
                                        ?.filter(
                                          (dat2) =>
                                            dat2?.name ===
                                            data?.person?.name?.value
                                        )?.[0]
                                        ?.cbs_data?.SecuredCreditLimit?.toLocaleString(
                                          "en-US",
                                          {
                                            style: "currency",
                                            currency: "SGD",
                                          }
                                        )}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Credit Score:
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 7,
                                      }}
                                    >
                                      {
                                        allCBSData?.filter(
                                          (dat2) =>
                                            dat2?.name ===
                                            data?.person?.name?.value
                                        )?.[0]?.cbs_data?.CreditScore
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Probability of Default:
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 7,
                                      }}
                                    >
                                      {
                                        allCBSData?.filter(
                                          (dat2) =>
                                            dat2?.name ===
                                            data?.person?.name?.value
                                        )?.[0]?.cbs_data?.ProbabilityofDefault
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Unsecured Credit Utilized:
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 7,
                                      }}
                                    >
                                      {allCBSData
                                        ?.filter(
                                          (dat2) =>
                                            dat2?.name ===
                                            data?.person?.name?.value
                                        )?.[0]
                                        ?.cbs_data?.UnsecuredCreditUtilized?.toLocaleString(
                                          "en-US",
                                          {
                                            style: "currency",
                                            currency: "SGD",
                                          }
                                        )}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Unsecured Credit Utilization in
                                      percentage:
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 7,
                                      }}
                                    >
                                      {(Number(
                                        allCBSData?.filter(
                                          (dat2) =>
                                            dat2?.name ===
                                            data?.person?.name?.value
                                        )?.[0]?.cbs_data
                                          ?.UnsecuredCreditUtilizationinpercentage
                                      ) || 0) + "%"}
                                    </Text>
                                  </View>
                                </View>
                                <View style={{ width: "50%" }}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Unsecured Credit Limit:
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 7,
                                      }}
                                    >
                                      {allCBSData
                                        ?.filter(
                                          (dat2) =>
                                            dat2?.name ===
                                            data?.person?.name?.value
                                        )?.[0]
                                        ?.cbs_data?.UnsecuredCreditLimit?.toLocaleString(
                                          "en-US",
                                          {
                                            style: "currency",
                                            currency: "SGD",
                                          }
                                        )}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Risk Grade:
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 7,
                                      }}
                                    >
                                      {
                                        allCBSData?.filter(
                                          (dat2) =>
                                            dat2?.name ===
                                            data?.person?.name?.value
                                        )?.[0]?.cbs_data?.RiskGrade
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Secured Credit Utilized:
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 7,
                                      }}
                                    >
                                      {allCBSData
                                        ?.filter(
                                          (dat2) =>
                                            dat2?.name ===
                                            data?.person?.name?.value
                                        )?.[0]
                                        ?.cbs_data?.SecuredCreditUtilized?.toLocaleString(
                                          "en-US",
                                          {
                                            style: "currency",
                                            currency: "SGD",
                                          }
                                        )}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Secured Credit Utilization in percentage:
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 7,
                                      }}
                                    >
                                      {(Number(
                                        allCBSData?.filter(
                                          (dat2) =>
                                            dat2?.name ===
                                            data?.person?.name?.value
                                        )?.[0]?.cbs_data
                                          ?.SecuredCreditUtilizationinpercentage
                                      ) || 0) + "%"}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Monthly Commitment/Installment Secured:
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 7,
                                      }}
                                    >
                                      {(
                                        Number(
                                          allCBSData?.filter(
                                            (dat2) =>
                                              dat2?.name ===
                                              data?.person?.name?.value
                                          )?.[0]?.cbs_data?.MonthlyCommitment
                                        ) || 0
                                      )?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "SGD",
                                      })}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                              <View
                                style={{
                                  padding: 8,
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                    gap: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "30%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Loan / Product Type</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "30%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Grantor / Bank</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "20%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Outstanding Balance</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "20%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Monthly Installment</Text>
                                  </View>
                                </View>

                                {allCBSData
                                  ?.filter(
                                    (dat2) =>
                                      dat2?.name === data?.person?.name?.value
                                  )?.[0]
                                  ?.cbs_data?.NoteworthyOngoingLoans?.map(
                                    (imp, index10) => (
                                      <View
                                        key={index10}
                                        style={{
                                          flexDirection: "row",
                                          width: "100%",
                                          backgroundColor:
                                            index10 % 2
                                              ? "rgba(199, 220, 236, 0.4)"
                                              : "rgba(199, 220, 236, 0.15)",
                                          padding: 4,
                                          gap: 10,
                                        }}
                                      >
                                        <View
                                          style={{
                                            width: "30%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>
                                            {imp.includes(
                                              "private residential purchase",
                                              ""
                                            )
                                              ? "Private Residential Purchase"
                                              : imp.includes(
                                                  "motor vehicle loan",
                                                  ""
                                                )
                                              ? "Motor Vehicle Loan"
                                              : imp.includes("hdb loan", "")
                                              ? "HDB Loan"
                                              : ""}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "30%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>
                                            {imp
                                              .replace(
                                                "private residential purchase",
                                                ""
                                              )
                                              .replace("motor vehicle loan", "")
                                              .replace("hdb loan", "")
                                              .trim()
                                              .split(" ")[0]
                                              .toUpperCase()}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "20%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>
                                            {parseFloat(
                                              imp
                                                .split("   ")
                                                [
                                                  imp.split("   ").length - 4
                                                ].replace(/,/g, "")
                                            ).toLocaleString("en-US", {
                                              style: "currency",
                                              currency: "SGD",
                                            })}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "20%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>
                                            {allCBSData?.filter(
                                              (dat2) =>
                                                dat2?.name ===
                                                data?.person?.name?.value
                                            )?.[0]?.cbs_data
                                              ?.CBSImpLoanSupplementaryInstallment?.[
                                              index10
                                            ]
                                              ? (
                                                  parseFloat(
                                                    allCBSData
                                                      ?.filter(
                                                        (dat2) =>
                                                          dat2?.name ===
                                                          data?.person?.name
                                                            ?.value
                                                      )?.[0]
                                                      ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                        index10
                                                      ].split("   ")
                                                      [
                                                        allCBSData
                                                          ?.filter(
                                                            (dat2) =>
                                                              dat2?.name ===
                                                              data?.person?.name
                                                                ?.value
                                                          )?.[0]
                                                          ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                            index10
                                                          ].split("   ")
                                                          .length - 4
                                                      ]?.replace(/,/g, "")
                                                  ) +
                                                  parseFloat(
                                                    allCBSData
                                                      ?.filter(
                                                        (dat2) =>
                                                          dat2?.name ===
                                                          data?.person?.name
                                                            ?.value
                                                      )?.[0]
                                                      ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                        index10
                                                      ].split("   ")
                                                      [
                                                        allCBSData
                                                          ?.filter(
                                                            (dat2) =>
                                                              dat2?.name ===
                                                              data?.person?.name
                                                                ?.value
                                                          )?.[0]
                                                          ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                            index10
                                                          ].split("   ")
                                                          .length - 3
                                                      ]?.replace(/,/g, "")
                                                  )
                                                ).toLocaleString("en-US", {
                                                  style: "currency",
                                                  currency: "SGD",
                                                })
                                              : null}
                                          </Text>
                                        </View>
                                      </View>
                                    )
                                  )}
                              </View>
                              <View
                                style={{
                                  padding: 8,
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Last 6 Months (Unsecured) Credit Utilization
                                    Graph:
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    width: "100%",
                                    fontSize: 7,
                                  }}
                                >
                                  <View style={{ flexDirection: "row" }}>
                                    <View
                                      style={{
                                        width: "5%",
                                        position: "relative",
                                      }}
                                    >
                                      <View style={{ height: "15px" }}>
                                        <Text
                                          style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                          }}
                                        >
                                          {allCBSData
                                            ?.filter(
                                              (dat2) =>
                                                dat2?.name ===
                                                data?.person?.name?.value
                                            )?.[0]
                                            ?.cbs_data?.Last6MonthsGraphChartMaxData?.toLocaleString(
                                              "en-US",
                                              {
                                                style: "currency",
                                                currency: "SGD",
                                              }
                                            )}
                                        </Text>
                                      </View>
                                      <View style={{ height: "220px" }}></View>
                                    </View>
                                    <View
                                      style={{
                                        width: "95%",
                                      }}
                                    >
                                      <View
                                        style={{
                                          width: "100%",
                                          flexDirection: "row",
                                          marginTop: "25px",
                                          borderBottom: "1px solid lightgrey",
                                          height: "200px",
                                          alignItems: "flex-end",
                                        }}
                                      >
                                        <View
                                          style={{
                                            height: "200px",
                                            borderLeft: "1px solid lightgrey",
                                          }}
                                        />
                                        {allCBSData
                                          ?.filter(
                                            (dat2) =>
                                              dat2?.name ===
                                              data?.person?.name?.value
                                          )?.[0]
                                          ?.cbs_data?.Last6MonthsGraphChartData?.map(
                                            (dat30, index30) => (
                                              <View
                                                key={index30}
                                                style={{
                                                  marginLeft: "4%",
                                                  marginRight: "4%",
                                                  width: "8%",
                                                  borderTopLeftRadius: "4px",
                                                  borderTopRightRadius: "4px",
                                                  height: `${
                                                    (parseFloat(
                                                      dat30.value.replace(
                                                        /,/g,
                                                        ""
                                                      )
                                                    ) /
                                                      allCBSData?.filter(
                                                        (dat2) =>
                                                          dat2?.name ===
                                                          data?.person?.name
                                                            ?.value
                                                      )?.[0]?.cbs_data
                                                        ?.Last6MonthsGraphChartMaxData) *
                                                    100
                                                  }%`,
                                                  backgroundColor:
                                                    "rgba(217, 238, 255, 1)",
                                                }}
                                              ></View>
                                            )
                                          )}
                                      </View>
                                      <View
                                        style={{
                                          marginTop: "8px",
                                          flexDirection: "row",
                                          fontSize: 7,
                                        }}
                                      >
                                        {allCBSData
                                          ?.filter(
                                            (dat2) =>
                                              dat2?.name ===
                                              data?.person?.name?.value
                                          )?.[0]
                                          ?.cbs_data?.Last6MonthsGraphChartData?.map(
                                            (dat, index) => (
                                              <View
                                                key={index}
                                                style={{
                                                  width: "16%",
                                                  textAlign: "center",
                                                }}
                                              >
                                                <Text
                                                  style={{
                                                    fontWeight: 600,
                                                    fontSize: 7,
                                                  }}
                                                >
                                                  {dat.date
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    dat.date.slice(1)}
                                                </Text>
                                                <Text
                                                  style={{
                                                    fontWeight: 600,
                                                    fontSize: 7,
                                                  }}
                                                >
                                                  {parseFloat(
                                                    dat.value.replace(/,/g, "")
                                                  ).toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  })}
                                                </Text>
                                              </View>
                                            )
                                          )}
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>
                          ) : null}
                        </View>
                      </Page>
                    ))
                  : null}
              </Document>
            </PDFViewer>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default CbsCampagin;
